import React from 'react';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

function TrendIcon(data) {
	const { label, color, formatted, isPositive } = data;
	return (
		<div style={{ display: 'flex' }}>
			<div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
				{label}
			</div>
			<div
				style={{
					paddingLeft: label ? 10 : 0,
					fontSize: 'small',
					color,
					width: label ? 'auto' : '100%',
				}}
			>
				{formatted && (isPositive ? <TrendingUpIcon /> : <TrendingDownIcon />)}
				{formatted}
			</div>
		</div>
	);
}

export default TrendIcon;
