import ReactDOM from 'react-dom';
import _ from 'lodash';
import authToken from './auth-token';

class BrowserUtils {
	static urlParams() {
		const sPageURL = window.location.search.substring(1);
		const sURLVariables = sPageURL.split('&');
		const res = {};
		for (let i = 0; i < sURLVariables.length; i++) {
			const sParameterName = sURLVariables[i].split('=');
			res[sParameterName[0]] = decodeURIComponent(sParameterName[1]);
		}
		return res;
	}

	static makeQs(url, qs) {
		const enc = Object.entries(qs || {}).filter(([k, v]) => v).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join('&');
		return `${url}${enc ? `?${enc}` : ''}`;
	}

	static findScrollContainer(elm) {
		for (let e = elm; e && e !== window.document.documentElement; e = e.parentElement) {
			const { overflowY } = window.getComputedStyle(e);
			if (overflowY === 'auto' || overflowY === 'scroll') {
				return e;
			}
		}
		return window.document.documentElement;
	}

	static async keepScrollPos(fn) {
		const { scrollX, scrollY } = window;
		await fn();
		setTimeout(() => scrollTo(scrollX, scrollY));
	}

	static async keepElmScrollPos(elm, fn) {
		const container = BrowserUtils.findScrollContainer(elm);
		const { x: oldX, y: oldY } = elm.getBoundingClientRect();
		await fn();
		const { x: newX, y: newY } = elm.getBoundingClientRect();
		container.scrollBy(newX - oldX, newY - oldY);
	}

	static goBackFn(history, { url } = {}) {
		const oldLoc = _.clone(history.location);
		return () => {
			if (!_.isEqual(oldLoc, history.location)) {
				return; // user has already navigated, don't do anything
			}
			if (url) {
				history.push(url);
			} else if (history.length > 2) {
				history.goBack();
			} else {
				history.push('/');
			}
		};
	}

	static goOneLevelUpFn(history) {
		const parts = location.pathname.split('/').filter((p) => p);
		const newPath = `/${_.initial(parts).join('/')}`;
		return () => history.push(newPath);
	}

	static selectorHasFormErrors(selectorComponent) {
		const { props, ref } = selectorComponent;
		if (!props || !ref) {
			return false;
		}
		const { form, forms } = props;
		if (!form && !forms) {
			return false;
		}
		let otherForms = forms?.forms || form?.formCollection()?.forms || [];
		otherForms = otherForms.filter((f) => {
			if (!f || f === form) {
				return false;
			}
			const dom = ReactDOM.findDOMNode(f);
			return dom && (dom.contains(ref) || ref.contains(dom));
		});
		const allForms = [
			...(form ? [form] : []),
			...otherForms,
		];
		const errors = _.flatten(allForms.map((f) => f.hasErrors()).filter((f) => f));
		const err = (errors || []).find((name) => ref.querySelector(`[name='${name}']`));
		return !!err;
	}

	static downloadTextFile(text, fileName) {
		const blob = new Blob(['\ufeff', text], { type: 'text/csv;charset=utf-8' });
		const url = URL.createObjectURL(blob);
		BrowserUtils.clickLink(url, fileName);
	}

	static clickLink(href, fileName) {
		authToken.syncToCookie();
		const link = document.createElement('a');
		link.setAttribute('download', fileName);
		link.setAttribute('target', '_blank');
		link.setAttribute('href', href);
		link.dispatchEvent(new MouseEvent('click', { view: window, bubbles: true, cancelable: true }));
	}

	static loadScript({ src, attribs }) {
		const scriptElm = document.createElement('script');
		scriptElm.type = 'text/javascript';
		scriptElm.async = 'async';
		scriptElm.src = src;
		_.forOwn(attribs, (val, key) => {
			scriptElm.setAttribute(key, val);
		});
		document.head.appendChild(scriptElm);
	}

	static copyTextToClipboard(text, asHtml, elmCb) {
		const ofsBefore = window.pageYOffset || 0;
		let element;
		if (asHtml) {
			element = document.createElement('div');
			element.innerHTML = `<div>start</div>${text}<div>end</div>`;
			element.style.position = 'fixed';
			element.style.top = 0;
			element.style.left = 0;
			element.style.zIndex = 10000;
			document.body.appendChild(element);
			const selection = window.getSelection();
			const range = document.createRange();
			let afterElm = element.firstChild;
			if (((afterElm.nextSibling || {}).textContent || '')[0] === '\\') {
				afterElm = afterElm.nextSibling; // well, don't know why \\n is sometimes added in here, but it sometimes is..
			}
			range.setStartAfter(afterElm);
			range.setEndBefore(element.lastChild);
			if (elmCb) {
				elmCb(element, range);
			}
			selection.removeAllRanges();
			selection.addRange(range);
		} else {
			element = document.createElement('textarea');
			element.style = {
				position: 'fixed',
				top: 0,
				left: 0,
				width: '2em',
				height: '2em',
				padding: 0,
				border: 'none',
				outline: 'none',
				boxShadow: 'none',
				background: 'transparent',
			};
			element.value = text;
			document.body.appendChild(element);
			element.focus();
			element.select();
		}
		document.execCommand('copy');
		document.body.removeChild(element);
		const ofsNow = window.pageYOffset || 0;
		scrollBy(0, ofsBefore - ofsNow);
	}

	static isTouchDevice() {
		return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
	}
}
window.copyTextToClipboard = BrowserUtils.copyTextToClipboard;
export default BrowserUtils;
