import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import PortfolioData from './portfolioData';
import { ActionButton } from '../ActionButton/ActionButton';
import OperationWrapper from '../OperationWrapper';
import DateUtils from '../../lib/dateUtils';
import ReactUtils from '../../lib/reactUtils';
import RecordTableTools from '../RecordTableTools';
import DataTable from '../DataTable';
import PopupSelector from '../PopupSelector';
import { withUpdate, FormOf, Scope } from '../Wrappers';
import FormCollection from '../../lib/formCollection';
import Base from '../../layouts/Base';
import Checkbox from '../Checkbox';
import DynamicSearchFilter from '../DynamicSearchFilter';
import DatePicker from '../DatePicker';
import RadioButtonGroup from '../RadioButtonGroup';
import Select from '../Select';
import DataTableWithMemory from '../DataTable/dataTableWithMemory';
import { DynamicExports } from '../../api/relevant';
import CsvImport from '../CsvImport';
import MiscUtils from '../../lib/miscUtils';
import ExpandSelector from '../ExpandSelector';
import { ConfirmDialog } from '../ConfirmDialog';
import { Dialog } from '../Dialog';

const AllSeqObjectDimensions = require('relevant-shared/mappingDimensions/allSeqObjectDimensions');
const { Advertiser } = DynamicExports;

@withUpdate
class PortfolioEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			onlyShowAdmins: true,
		};
		this.forms = new FormCollection();
		this.data = new PortfolioData();
		this.fld = ReactUtils.fld(this);
	}

	filteredUsers() {
		const { onlyShowAdmins } = this.state;
		return this.data.users.filter((u) => !(onlyShowAdmins && u.pubName));
	}

	async doImport(data) {
		if (!data.length) {
			await Base.renderGlobal((close) => (
				<Dialog
					open
					status="success"
					text="Nothing to do"
					onClose={close}
				/>
			));
			return false;
		}
		const ok = await Base.renderGlobal((closeFn) => (
			<ConfirmDialog
				open
				text={(
					<span>
						Are you sure you want to assign&nbsp;
						<span style={{ fontWeight: 'bold' }}>
							{_.sumBy(_.values(data), ({ arr }) => arr.length)}
						</span>
						{' '}
						advertiser(s)
					</span>
				)}
				onAny={closeFn}
			/>
		));
		if (!ok) {
			return false;
		}
		const advs = await Advertiser.call('updateObjects', {
			objects: _.flatten(data.map(({ arr }) => arr.map((e) => ({ name: e.advName })))),
			returnObjects: true,
		});
		const advsByName = _.keyBy(advs, 'name');
		for (const { user, arr } of data) {
			await this.data.moveAdvertisers({
				advNrs: arr.map(({ advName }) => advsByName[advName].seq),
				toUserId: user.id,
			});
		}
		return true;
	}

	async handleCsvImport(orgRows) {
		const usersByName = _.groupBy(this.filteredUsers(), (u) => u.fullname.toLowerCase());
		const rows = _.values(_.mapValues(_.groupBy(orgRows.filter((r) => r.advName), 'advName'), (arr) => _.last(arr)));
		let nonMatched = [];
		const withUser = rows.map((row) => {
			const users = usersByName[row.userName.toLowerCase()];
			if (!users) {
				nonMatched.push(row);
				return null;
			}
			// if there are multiple users with same name => select admin if available
			row.user = users.find((u) => !u.pubName) || users[0];
			return row;
		}).filter((row) => row);
		nonMatched = _.uniqBy(nonMatched, 'advName');
		const data = _.values(_.groupBy(withUser, (u) => u.user.id)).map((arr) => ({ user: arr[0].user, arr, userId: arr[0].user.id }));
		Base.renderGlobal((done) => (
			<PopupSelector
				forceExpanded
				onApplyChanges={async ({ preventDefault }) => {
					if (!(await this.doImport(data))) {
						preventDefault();
					} else {
						done();
						this.op.reload();
					}
				}}
				onCancel={done}
			>
				{!!nonMatched.length && (
					<ExpandSelector
						title="Rows now matching any user"
						selected={nonMatched}
					>
						<DataTable
							showCheckboxes={false}
							selectableRows={false}
							identifier={(row) => row.advName}
							definitions={[
								{ key: 'userName', title: 'User' },
								{ key: 'advName', title: 'Advertiser' },
							]}
							data={MiscUtils.alphaSorted(nonMatched, 'userName')}
						/>
					</ExpandSelector>
				)}
				<DataTable
					showCheckboxes={false}
					selectableRows={false}
					identifier={(row) => row.userId}
					definitions={[
						{
							key: 'userId',
							title: 'User',
							format: (__, { user }) => user.fullname,
							style: { width: 200 },
						},
						{
							key: 'userId',
							title: 'Advertisers',
							format: (__, { arr }) => (
								<ExpandSelector
									title="Advertisers"
									selected={arr}
								>
									{arr.map((elm) => (
										<div key={elm.advName}>
											{elm.advName}
										</div>
									))}
								</ExpandSelector>
							),
						},
					]}
					data={MiscUtils.alphaSorted(data, (u) => u.user.fullname)}
				/>
			</PopupSelector>
		));
	}

	renderPortfolio({ field }, { op }) {
		const { current, selectedAdvertisers, opDate } = this.state;
		const userData = this.data.userStatesFor(opDate).userData(current.id);
		const TYPES = {
			prev: { desc: 'Previous' },
			now: { desc: 'Current' },
			next: { desc: 'Future' },
		};
		const userAdvDatas = _.values(userData.byAdvNr).filter((ud) => _.keys(TYPES).find((k) => this.state[k] && !_.isEmpty(ud[k])));
		return (
			<Grid container spacing={3}>
				<Grid item xs={5}>
					<Paper>
						<Box padding={2}>
							<Scope
								selected={[]}
								startDate={DateUtils.today()}
								endDate={null}
								content={(scope, { selected, startDate, endDate }) => (
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Typography variant="h2">
												Add advertiser(s)
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<DatePicker
												floatingLabelText="Add from date"
												autoOk
												maxDate={endDate}
												{...scope.fld('startDate')}
												canClear
											/>
										</Grid>
										<Grid item xs={6}>
											<DatePicker
												floatingLabelText="Add to date"
												autoOk
												minDate={startDate}
												{...scope.fld('endDate')}
												canClear
											/>
										</Grid>
										<Grid item xs={12}>
											<DynamicSearchFilter
												selected={selected}
												onChange={(s) => scope.setState({ selected: s })}
												type="programmatic"
												hideEmptySelected
												dimension="advNr"
												label="advertiserNr"
												createUsingDimObject={AllSeqObjectDimensions.byName('advertiser')}
												noObjectCreate
												extraTableDefs={[
													{
														key: 'advNr',
														title: 'Already allocated to',
														format: (advNr) => {
															const mapping = this.data.mappingsByAdv[advNr];
															if (!mapping) {
																return null;
															}
															return (
																<div>
																	{mapping.entriesFrom(startDate).map((entry) => {
																		let color;
																		if (entry.userId === current.id) {
																			color = entry.isForDate(startDate) ? 'success.main' : 'success.light';
																		} else {
																			color = entry.isForDate(startDate) ? undefined : 'grey.600';
																		}
																		const { user } = entry;
																		const shorten = (s) => (s.length > 20 ? `${s.substr(0, 18)}..` : s);
																		return (
																			<Tooltip title={user.pubName || 'ADMIN'} key={user.id}>
																				<Box color={color} key={`${entry.start}`}>{shorten(user.fullname)}</Box>
																			</Tooltip>
																		);
																	})}
																</div>
															);
														},
													},
												]}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12}>
											<ConfirmDialog
												open
												text={`Do you want to add ${selected.length} advertisers`}
												onConfirm={() => {
													op.reload(async () => {
														await this.data.moveAdvertisers({
															advNrs: selected,
															startDate,
															endDate,
															toUserId: current.id,
														});
														scope.reset();
														this.update();
													});
												}}
											>
												<ActionButton
													disabled={!selected.length}
													label="Add"
												/>
											</ConfirmDialog>
										</Grid>
									</Grid>
								)}
							/>
						</Box>
					</Paper>
				</Grid>
				<Grid item xs={7}>
					<Paper>
						<Box padding={2}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="h2">
										Advertisers
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<DatePicker
										floatingLabelText="Current date"
										autoOk
										{...this.fld('opDate')}
									/>
								</Grid>
								<Grid item>
									<ActionButton
										label="Move"
										disabled={!selectedAdvertisers.length}
										onClick={() => this.renderMovePortfolio({
											advNrs: selectedAdvertisers.map((e) => e.advNr),
											fromUserId: current.id,
											initOp: 'move',
											initDate: opDate,
										})}
									/>
								</Grid>
								<Grid item>
									<ActionButton
										label="Delete"
										disabled={!selectedAdvertisers.length}
										onClick={() => this.renderMovePortfolio({
											advNrs: selectedAdvertisers.map((e) => e.advNr),
											fromUserId: current.id,
											initOp: 'del',
											initDate: opDate,
										})}
									/>
								</Grid>
								<Grid item xs={12}>
									{_.entries(TYPES).map(([key, { desc }]) => (
										<Checkbox
											key={key}
											label={desc}
											{...this.fld(key)}
										/>
									))}
								</Grid>
								<Grid item xs={12}>
									<DataTableWithMemory
										showCheckboxes={!!userAdvDatas.length}
										identifier={(row) => row.advNr}
										onChange={(selected) => {
											this.setState({ selectedAdvertisers: selected });
										}}
										selected={selectedAdvertisers}
										definitions={[
											{
												key: 'advName',
												title: 'Advertiser',
											},
											{
												key: 'advNr',
												title: 'Time range',
												format: (advNr, uad) => {
													const dateStr = (d) => (d ? moment.utc(d).format('YYYY-MM-DD') : '...');
													function formatEntry(entry) {
														return entry.start || entry.end ? `${dateStr(entry.start)} to ${dateStr(entry.end)}` : 'ALWAYS';
													}
													return (
														<div>
															{uad.prev.map((entry) => (
																<Box key={entry.start} color="grey.600">{formatEntry(entry)}</Box>
															))}
															{uad.now ? (
																<div>
																	<strong>{formatEntry(uad.now)}</strong>
																</div>
															) : null}
															{uad.next.map((entry) => (
																<div key={entry.start}>{formatEntry(entry)}</div>
															))}
														</div>
													);
												},
											},
										]}
										data={_.sortBy(userAdvDatas, (uad) => uad.advName)}
									/>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		);
	}

	renderMovePortfolio({
		advNrs, fromUserId, initOp, initDate,
	}) {
		return Base.renderGlobal((done) => {
			const obj = {
				startDate: initDate || moment.utc().startOf('day').toDate(),
				endDate: null,
				toUser: null,
				op: initOp || 'move',
				useFullTimeRange: false,
			};
			return (
				<FormOf
					model={obj}
					formCollection={this.forms}
					content={(p) => (
						<PopupSelector
							onApplyChanges={async ({ preventDefault }) => {
								const ok = await Base.renderGlobal((closeFn) => (
									<ConfirmDialog
										open
										text={`${obj.op === 'move' ? 'Move' : 'Delete'} ${advNrs.length} advertiser(s)`}
										onAny={closeFn}
									/>
								));
								if (!ok) {
									preventDefault();
									return;
								}
								done();
								this.op.reload(() => this.data.moveAdvertisers({
									advNrs,
									startDate: obj.startDate,
									endDate: obj.endDate,
									fromUserId: (obj.useFullTimeRange ? null : fromUserId),
									toUserId: obj.op === 'move' ? obj.toUser : null,
								}));
							}}
							onCancel={() => done()}
							okDisabled={obj.op === 'move' && !obj.toUser}
							title="Move / Delete advertiser"
							content={() => (
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<DatePicker
											floatingLabelText="Perform operation from"
											autoOk
											maxDate={obj.endDate}
											{...p.field('startDate')}
											canClear
											fullWidth
										/>
									</Grid>
									<Grid item xs={6}>
										<DatePicker
											floatingLabelText="Perform operation to"
											minDate={obj.startDate}
											autoOk
											{...p.field('endDate')}
											canClear
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<Checkbox
											label="Perform operation for full time range (not only periods allocated to the current user)"
											{...p.field('useFullTimeRange')}
										/>
									</Grid>
									<Grid item xs={12}>
										<RadioButtonGroup
											{...p.field('op')}
											selected={obj.op}
											heading="What to do"
											items={[
												{ name: 'Delete', value: 'del' },
												{ name: 'Move', value: 'move' },
											]}
										/>
									</Grid>
									{obj.op === 'move' && (
										<Grid item xs={12}>
											<Select
												label="Move to user"
												{...p.field('toUser')}
												items={this.filteredUsers().map((u) => ({ label: u.fullname, value: u.id }))}
												fullWidth
											/>
										</Grid>
									)}
								</Grid>
							)}
							hideLink
							expanded
						/>
					)}
				/>
			);
		});
	}

	render() {
		const { onCancel } = this.props;
		const { current } = this.state;
		const { userStates } = this.data;

		const closeCurrent = () => this.setState({ current: null });

		const addCountCol = (key, title, render) => ({
			key: 'id',
			title,
			format: (__, user) => {
				const num = userStates.userData(user.id)[key].length;
				return num ? render(num) : '-';
			},
		});
		return (
			<>
				<Grid item xs={12}>
					<Paper>
						<Box padding={2}>
							<OperationWrapper
								fn={async (op) => {
									this.op = op;
									await this.data.reload();
									this.update();
								}}
								content={() => (
									<div>
										{current && (
											<FormOf
												model={current}
												formCollection={this.forms}
												content={(p) => (
													<PopupSelector
														title="Edit portfolio"
														content={(selector) => this.renderPortfolio(p, selector)}
														onCancel={closeCurrent}
														onApplyChanges={closeCurrent}
														okLabel="Close"
														okBtnStyle={{ marginTop: 0 }}
														hideLink
														expanded
														size="lg"
													/>
												)}
											/>
										)}
										<Checkbox
											label="Only include administrators"
											{...this.fld('onlyShowAdmins')}
										/>
										<DataTable
											showCheckboxes={false}
											selectableRows={false}
											identifier={(row) => row.id}
											definitions={[
												{
													key: 'id',
													title: 'User',
													format: (__, user) => user.fullname,
												},
												{
													key: 'id',
													title: 'Publisher',
													format: (__, { pubName }) => (pubName === null ? (<Box component="strong" color="success.main">ADMIN</Box>) : pubName),
												},
												addCountCol('prev', 'Former advertisers', (num) => <Box component="span" color="grey.600">{num}</Box>),
												addCountCol('now', 'Current advertisers', (num) => <Box component="strong" color="success.main">{num}</Box>),
												addCountCol('next', 'Future advertisers', (num) => num),
												{
													key: 'id',
													title: '',
													align: 'right',
													format: (__, user) => {
														const data = userStates.userData(user.id);
														return (
															<RecordTableTools
																editAction={() => this.setState({
																	current: user,
																	selectedAdvertisers: [],
																	opDate: DateUtils.today(),
																	prev: false,
																	now: true,
																	next: true,
																})}
																noConfirmDelete
																deleteAction={!(data.now.length || data.next.length) ? null : (() => this.renderMovePortfolio({
																	advNrs: [...data.now, ...data.next].map((e) => e.advNr),
																	fromUserId: user.id,
																	initOp: 'del',
																}))}
															/>
														);
													},
												},
											]}
											data={this.filteredUsers()}
										/>
										<CsvImport
											colSelectors={[
												{
													name: 'advName',
													label: 'Advertiser name column',
													required: true,
												},
												{
													name: 'userName',
													label: 'User name column',
													required: true,
												},
											]}
											onCsvImported={(rows) => { this.handleCsvImport(rows); }}
										/>
									</div>
								)}
							/>
						</Box>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<ActionButton
						label="Cancel"
						onClick={() => onCancel()}
						variant="text"
						color="primary"
					/>
				</Grid>
			</>
		);
	}
}

PortfolioEditor.propTypes = {
	onCancel: PropTypes.func.isRequired,
};

PortfolioEditor.defaultProps = {
};

export default PortfolioEditor;
