exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".dashboardPlaceholderDroppable__src-containers-Dashboard-styles__11GZD {\n   min-height: 45vh;\n   border-radius: 2px;\n}\n\n.dashboardPlaceholderText__src-containers-Dashboard-styles__3Cm-3 {\n    vertical-align: middle;\n    text-align: center;\n    color: #262F3D;\n    text-transform: uppercase;\n    opacity: 0.5;\n    line-height: 45vh;\n}\n\n.dragHandle__src-containers-Dashboard-styles__1Jg7x {\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n}\n\n.dragIcon__src-containers-Dashboard-styles__1tue1 {\n    display: block !important;\n    width: 1.9rem !important;\n    height: 1.9rem !important;\n}\n", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/worker/temp/relevant-yield/relevant-frontend/src/containers/Dashboard/styles.css"],"names":[],"mappings":"AAAA;GACG,iBAAgB;GAChB,mBAAkB;CACrB;;AAEA;IACI,uBAAsB;IACtB,mBAAkB;IAClB,eAAc;IACd,0BAAyB;IACzB,aAAY;IACZ,kBAAiB;CACrB;;AAEA;IACI,6BAA4B;IAC5B,8BAA6B;CACjC;;AAEA;IACI,0BAAyB;IACzB,yBAAwB;IACxB,0BAAyB;CAC7B","file":"styles.css","sourcesContent":[".dashboardPlaceholderDroppable {\n   min-height: 45vh;\n   border-radius: 2px;\n}\n\n.dashboardPlaceholderText {\n    vertical-align: middle;\n    text-align: center;\n    color: #262F3D;\n    text-transform: uppercase;\n    opacity: 0.5;\n    line-height: 45vh;\n}\n\n.dragHandle {\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n}\n\n.dragIcon {\n    display: block !important;\n    width: 1.9rem !important;\n    height: 1.9rem !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"dashboardPlaceholderDroppable": "dashboardPlaceholderDroppable__src-containers-Dashboard-styles__11GZD",
	"dashboardPlaceholderText": "dashboardPlaceholderText__src-containers-Dashboard-styles__3Cm-3",
	"dragHandle": "dragHandle__src-containers-Dashboard-styles__1Jg7x",
	"dragIcon": "dragIcon__src-containers-Dashboard-styles__1tue1"
};