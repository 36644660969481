exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".pageContentWrapper__src-pages-AdminList-styles__31Lg5 {\n\tpadding-bottom: 100px;\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/worker/temp/relevant-yield/relevant-frontend/src/pages/AdminList/styles.css"],"names":[],"mappings":"AAAA;CACC,sBAAqB;CACtB","file":"styles.css","sourcesContent":[".pageContentWrapper {\n\tpadding-bottom: 100px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"pageContentWrapper": "pageContentWrapper__src-pages-AdminList-styles__31Lg5"
};