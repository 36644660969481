import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '../TextField';
import Select from '../Select';
import JobButton from '../JobButton';
import Base from '../../layouts/Base';
import { DemoHide } from '../Wrappers';
import { ConfirmDialog } from '../ConfirmDialog';

const SECRET_FIELDS = {
	password: 'Password',
	apiKey: 'API Key',
	consumerKey: 'Consumer Key',
	consumerSecret: 'Consumer Secret',
	nextConsumerKey: 'Next Consumer Key',
	nextConsumerSecret: 'Next Consumer Secret',
	serviceAccountJson: 'Service Account Json',
	accessToken: 'Access Token',
	refreshToken: 'Refresh Token',
};

class Credentials extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			model, field, style, refreshTokenFn, noRequire,
		} = this.props;
		const {
			fields: orgFields, isNew, hasOptions, authType,
		} = model;
		const fldArr = hasOptions ? orgFields.find((opt) => opt.name === authType).fields : orgFields;
		const fields = _.zipObject(fldArr, Array(fldArr.length).fill(true));
		return (
			<Card style={style}>
				<CardContent>
					<Typography variant="h2">
						Credentials
					</Typography>
					{hasOptions
						&& (
							<Select
								{...field('authType')}
								label="Authentication type"
								items={orgFields.map((opt) => ({ label: opt.description, value: opt.name }))}
								fullWidth
								margin="normal"
							/>
						)}
					{fields.username
						&& (
							<DemoHide cond={!isNew}>
								<TextField
									{...field('username')}
									required={!noRequire}
									{...(model.usernameIsEmail ? { email: true } : {})}
									label="User"
									fullWidth
									margin="normal"
								/>
							</DemoHide>
						)}
					{fields.verificationEmail
						&& (
							<DemoHide cond={!isNew}>
								<TextField
									{...field('verificationEmail')}
									email
									label="Verification email"
									fullWidth
									margin="normal"
								/>
							</DemoHide>
						)}
					{fields.userId
						&& (
							<DemoHide cond={!isNew}>
								<TextField
									{...field('userId')}
									required={!noRequire}
									label="User ID"
									fullWidth
									margin="normal"
								/>
							</DemoHide>
						)}
					{fields.publisherUserId
						&& (
							<DemoHide cond={!isNew}>
								<TextField
									{...field('publisherUserId')}
									required={!noRequire}
									label="Publisher User ID"
									fullWidth
									margin="normal"
								/>
							</DemoHide>
						)}
					{fields.accountId
						&& (
							<DemoHide cond={!isNew}>
								<TextField
									{...field('accountId')}
									required={!noRequire}
									label="Account ID"
									fullWidth
									margin="normal"
								/>
							</DemoHide>
						)}
					{_.map(SECRET_FIELDS, (val, key) => {
						const isRequired = ((key in model) || !model[`${key}Len`])
							&& key !== 'nextConsumerKey'
							&& key !== 'nextConsumerSecret'
							&& !noRequire;
						return fields[key] && (
							<TextField
								{...field(key)}
								key={key}
								required={isRequired}
								label={val}
								fullWidth
								margin="normal"
								autoComplete="off"
							/>
						);
					}).filter((c) => c)}
					{fields.realm
						&& (
							<TextField
								{...field('realm')}
								required={!noRequire}
								label="Realm"
								fullWidth
								margin="normal"
							/>
						)}
					{!!(refreshTokenFn && !model.isNew && fields.refreshToken && model.refreshTokenLen)
						&& (
							<JobButton
								label="Generate new access token"
								fn={() => new Promise((resolve) => {
									Base.renderGlobal((closeFn) => (
										<ConfirmDialog
											open
											text="Are you sure you want to generate a new access token?"
											onAny={async (ok) => {
												closeFn();
												if (ok) {
													await refreshTokenFn();
												}
												resolve();
											}}
										/>
									));
								})}
								color="secondary"
							/>
						)}
				</CardContent>
			</Card>
		);
	}
}

Credentials.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	style: PropTypes.object,
	refreshTokenFn: PropTypes.func,
	noRequire: PropTypes.bool,
};

Credentials.defaultProps = {
	style: {},
	refreshTokenFn: undefined,
	noRequire: false,
};

export default Credentials;
