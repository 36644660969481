const _ = require('lodash');
const TagUtils = require('./tagUtils');
const { MEDIA_TYPES, DEAL_TYPES, PAYMENT_TYPES } = require('../constants');

const UNLABELLED = '[Unlabelled]';

const percentChange = (from, to, maxPercent = 1000) => {
	if (!from) {
		return to ? (maxPercent * (to < 0 ? -1 : 1)) : 0;
	}
	const multi = (from < 0 ? -1 : 1);
	return Math.max(Math.min(((to - from) / from) * multi * 100, maxPercent), -maxPercent);
};

const convertMediaTypeId = (mediaTypeId) => {
	if (!mediaTypeId) {
		return 0;
	}
	if (_.isNumber(mediaTypeId)) {
		return mediaTypeId; // already done
	}
	if (!_.isString(mediaTypeId)) {
		return 0;
	}
	const lower = mediaTypeId.toLowerCase().replace(/\W/g, '');
	if (['video', 'instream', 'outstream', 'vast'].find((s) => lower.includes(s))) {
		return MEDIA_TYPES.video.id;
	}
	if (lower.includes('native')) {
		return MEDIA_TYPES.native.id;
	}
	if (['banner', 'display', 'image', 'html5'].find((s) => lower.includes(s))) {
		return MEDIA_TYPES.banner.id;
	}
	return 0;
};

const convertDealTypeId = (dealTypeId) => {
	if (!dealTypeId) {
		return 0;
	}
	if (_.isNumber(dealTypeId)) {
		return dealTypeId; // already done
	}
	if (!_.isString(dealTypeId)) {
		return 0;
	}
	const lower = dealTypeId.toLowerCase();
	if (['private', 'pmp'].find((s) => lower.includes(s))) {
		return DEAL_TYPES.private.id;
	}
	if (lower.includes('open')) {
		return DEAL_TYPES.open.id;
	}
	if (lower.includes('guaranteed')) {
		return DEAL_TYPES.progGuaranteed.id;
	}
	return 0;
};

const convertPaymentTypeId = (paymentTypeId) => {
	if (!paymentTypeId) {
		return 0;
	}
	if (_.isNumber(paymentTypeId)) {
		return paymentTypeId; // already done
	}
	if (!_.isString(paymentTypeId)) {
		return 0;
	}
	const lower = paymentTypeId.toLowerCase();
	if (lower.includes('cpc')) {
		return PAYMENT_TYPES.cpc.id;
	}
	if (lower.includes('cpm')) {
		return DEAL_TYPES.other.id;
	}
	return 0;
};

const toPx = (num) => {
	const res = parseInt(num, 10);
	if (Number.isNaN(res)) {
		return -1;
	}
	return Math.max(Math.min(res, 65535), 0);
};

const parseMultipleDimensions = (txt) => {
	const regex = /(\d+)\s*[x|X]\s*(\d+)/g;
	const matches = [];
	let match = regex.exec(txt);
	while (match != null) {
		matches.push({ width: toPx(match[1]), height: toPx(match[2]) });
		match = regex.exec(txt);
	}
	return matches;
};

const keyRenamedObj = (obj, fromToMap) => {
	const res = { ...obj };
	_.forEach(fromToMap, (v, k) => {
		if (k in res) {
			res[v] = res[k];
			delete res[k];
		}
	});
	return res;
};

const DB_REPLACE = [
	{ char: '.', replace: '_rlv_dot_' },
	{ char: '$', replace: '_rlv_dollar_' },
];

const DB_REPLACE_ENCODE = DB_REPLACE.map(({ char: from, replace: to }) => ({
	from, to, regexp: new RegExp(`\\${from}`, 'g'),
}));

const DB_REPLACE_DECODE = DB_REPLACE.map(({ char: to, replace: from }) => ({
	from, to, regexp: RegExp(from, 'g'),
}));

const transformKeys = (obj, replacers) => {
	_.forOwn(obj, (v, k) => {
		let newKey = k;
		replacers.forEach(({ from, to, regexp }) => {
			if (newKey.includes(from)) {
				newKey = newKey.replace(regexp, to);
			}
		});
		if (newKey !== k) {
			delete obj[k];
			obj[newKey] = v;
		}
	});
	return obj;
};

const dbEncodeKeys = (obj) => transformKeys(obj, DB_REPLACE_ENCODE);
const dbDecodeKeys = (obj) => transformKeys(obj, DB_REPLACE_DECODE);

const limiter = (maxConcurrent = 10, { maxOpsPerPeriod, periodMs } = {}) => {
	const ops = [];
	let running = 0;
	let reallyRunning = 0;
	let waitingDone = [];
	let waitException;
	const doneTimes = [];
	const hasPeriod = maxOpsPerPeriod && periodMs;
	let maxC = maxConcurrent;
	if (hasPeriod) {
		maxC = Math.min(maxConcurrent, maxOpsPerPeriod);
	}
	const waitForPeriod = () => {
		const now = new Date();
		while (doneTimes.length && now - doneTimes[0] > periodMs) {
			doneTimes.shift();
		}
		if (reallyRunning + doneTimes.length < maxOpsPerPeriod) {
			return null;
		}
		return new Promise((r) => setTimeout(r, doneTimes[0] - now));
	};

	const res = async (fn, catchExceptions) => {
		if (running >= maxC) {
			let resolver;
			const promise = new Promise((r) => { resolver = r; });
			ops.push(resolver);
			await promise;
			if (periodMs) {
				for (; ;) {
					const waitPromise = waitForPeriod();
					if (waitPromise) {
						await waitPromise;
					} else {
						break;
					}
				}
			}
		} else {
			running += 1;
		}
		reallyRunning += 1;
		try {
			const result = await fn();
			return result;
		} catch (e) {
			waitException = waitException || e;
			if (!catchExceptions) {
				throw e;
			}
		} finally {
			reallyRunning -= 1;
			if (hasPeriod) {
				doneTimes.push(new Date());
			}
			if (ops.length) {
				ops.shift()();
			} else {
				running -= 1;
				if (!running) {
					const waiters = waitingDone;
					const ex = waitException;
					waitingDone = [];
					waitException = undefined;
					waiters.forEach(({ resolve, reject }) => (ex ? reject(ex) : resolve()));
				}
			}
		}
	};
	/** Used for waiting until all functions are done, will throw first exception that happened (if any) */
	res.waitDone = () => {
		if (!running) {
			return undefined;
		}
		let cbs;
		const promise = new Promise((resolve, reject) => { cbs = { resolve, reject }; });
		waitingDone.push(cbs);
		return promise;
	};
	return res;
};

const varIsNonEmpty = (v) => {
	if (!v) {
		return false;
	}
	if (_.isBoolean(v) || _.isNumber(v)) {
		return true;
	}
	return !_.isEmpty(v);
};

// example getIdentifier({a: 'bla', 'b': 'blub'}, ['a', 'b']) => 'bla,blub'
const getIdentifier = (obj, flds) => (
	flds && flds.length ? flds.map((f) => encodeURIComponent(`${obj[f] || ''}`)).join(',') : undefined
);

// "reverses" getIdentifier(), TODO only works for strings and not ObjectId and doesn't set empty values..
const setByIdentifier = (obj, flds, identifier) => {
	const ids = (identifier || '').split(',').map(decodeURIComponent);
	if (ids.length !== flds?.length) {
		throw Error('id/flds mismatch');
	}
	flds.forEach((fld, idx) => {
		const id = ids[idx];
		if (id) {
			obj[fld] = id;
		}
	});
};

const createCsv = (data, CSV_COLS, colsUsed) => {
	if (!colsUsed) {
		colsUsed = Object.keys(CSV_COLS);
	}
	const toCell = (val) => (val.match(/[",\r\n]/i) ? `"${val.replace(/"/g, '""')}"` : val);
	const toRow = (arr) => `${arr.map((v) => toCell(`${v}`)).join(',')}\n`;
	let res = toRow(colsUsed.map((name) => CSV_COLS[name].name || CSV_COLS[name]));
	data.forEach((row) => {
		res += toRow(colsUsed.map((name) => row[name]));
	});
	return res;
};

const asyncFuncCache = (obj) => {
	let cache = {};
	const proxyBase = {
		obj,
		reset: async (fn) => {
			let res;
			try {
				if (fn) {
					res = await fn();
				}
			} finally {
				cache = {};
			}
			return res;
		},
	};
	return new Proxy(proxyBase, {
		get(__, name) {
			if (name in proxyBase) {
				return proxyBase[name];
			}
			const fn = obj[name];
			if (!_.isFunction(fn)) {
				return fn;
			}
			return async function (...args) {
				let res;
				for (;;) {
					const key = `__${name}__${JSON.stringify(args)}`;
					let initiator;
					try {
						if (!cache[key]) {
							initiator = true;
							cache[key] = fn.call(obj, ...args);
						}
						res = await cache[key];
						break;
					} catch (e) {
						delete cache[key];
						if (initiator) {
							throw e;
						}
					}
				}
				return res;
			};
		},
		has: (__, key) => key in obj,
	});
};

const capitalize = (str) => str[0].toUpperCase() + str.slice(1).toLowerCase();

const asyncEvent = () => {
	let d = {};
	return {
		wait: () => {
			if (!d.promise) {
				d.promise = new Promise((r) => { d.resolve = r; });
			}
			return d.promise;
		},
		trigger() {
			const { resolve } = d;
			if (resolve) {
				d = {};
				resolve();
			}
		},
	};
};

const pubUserReportOptions = ({ userReportOptions: globalOptions }, pub) => {
	if (!pub) {
		return globalOptions;
	}
	const { userReportOptions } = pub;
	if (userReportOptions && userReportOptions.overrideDefaults) {
		return userReportOptions;
	}
	return globalOptions;
};

const arrayCallbacks = (arr, cb) => {
	const METHODS = ['push', 'pop', 'shift', 'unshift', 'splice', 'reverse', 'sort', 'fill', 'copyWithin'];
	METHODS.forEach((fnName) => {
		const orgFn = arr[fnName];
		if (orgFn) {
			arr[fnName] = function (...args) {
				const res = orgFn.call(this, ...args);
				cb(fnName, ...args);
				return res;
			};
		}
	});
};

// Like Promise.all() but no need to do try-catch to avoid unhandled promise rejections for 2+ exception
const promiseAllSafe = (arr) => {
	let exceptions = 0;
	return Promise.all(arr.map((p) => (async () => {
		try {
			const res = await p;
			return res;
		} catch (e) {
			exceptions += 1;
			if (exceptions === 1) {
				throw e;
			} // else, ignore..
		}
		return undefined;
	})()));
};

// Same as TagUtils.onceEvent() but event can be waited for using await:
//   const ev = onceEvent();
//   ev.trigger();
//   await ev();
const onceEvent = () => {
	const ev = TagUtils.onceEvent();
	const res = (timeoutMs, noExceptionOnTimeout) => new Promise((resolve, reject) => {
		let done;
		const tId = timeoutMs && setTimeout(() => {
			if (!done) {
				if (noExceptionOnTimeout) {
					resolve();
				} else {
					reject(Error(`Timeout waiting event after ${timeoutMs} ms`));
				}
			}
		}, timeoutMs);
		ev.wait((result) => {
			if (done) {
				return;
			}
			done = true;
			if (tId) {
				clearTimeout(tId);
			}
			resolve(result);
		});
	});
	Object.assign(res, ev);
	return res;
};

const swap = (arr, i1, i2) => {
	const tmp = arr[i1];
	arr[i1] = arr[i2];
	arr[i2] = tmp;
	return arr;
};

const makeQs = (url, qs) => {
	const enc = Object.entries(qs || {}).filter(([, v]) => v).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join('&');
	return `${url}${enc ? `?${enc}` : ''}`;
};

const withMembers = async (obj, tempVals, fn) => {
	const hasOld = {};
	const oldVals = {};
	_.forOwn(tempVals, (v, k) => {
		// eslint-disable-next-line no-prototype-builtins
		hasOld[k] = obj.hasOwnProperty(k);
		oldVals[k] = obj[k];
	});
	try {
		Object.assign(obj, tempVals);
		const res = await fn();
		return res;
	} finally {
		_.forOwn(tempVals, (v, k) => {
			if (hasOld[k]) {
				obj[k] = oldVals[k];
			} else {
				delete obj[k];
			}
		});
	}
};

const mergeUnits = (units, keyFnOrFields, numberFields) => {
	const SEP = '_|^|_';
	const keyFn = _.isFunction(keyFnOrFields)
		? keyFnOrFields
		: ((u) => keyFnOrFields.map((fld) => `${u[fld]}`).join(SEP));
	const mainUnits = {};
	const numFields = numberFields || _.entries(units[0]).filter(([, v]) => _.isNumber(v)).map(([k]) => k);
	units.forEach((unit) => {
		const key = keyFn(unit);
		const prevUnit = mainUnits[key];
		if (prevUnit) {
			numFields.forEach((k) => {
				prevUnit[k] = (prevUnit[k] || 0) + (unit[k] || 0);
			});
		} else {
			mainUnits[key] = unit;
		}
	});
	return Object.values(mainUnits);
};

const base64Utf8Encode = (s) => btoa(new TextEncoder().encode(s).reduce((acc, c) => acc + String.fromCharCode(c), ''));
const base64Utf8Decode = (s) => new TextDecoder().decode(Uint8Array.from(atob(s), c => c.charCodeAt(0)));

const distribute = (avg = 1, toMulti = 10, steps = 100, { decExp = 0.7, incExp = 1.5, numDecimals } = {}) => {
	const getMultiplierNr = (fluctation, rand = Math.random()) => {
		if ((fluctation || 0) <= 1) {
			return 1;
		}
		const exp = Math.log(fluctation) / Math.LN2;
		const decrease = rand < 0.5;
		const modified = decrease ? (1 - (rand * 2)) ** decExp : ((rand - 0.5) * 2) ** incExp;
		let multiplier = 2 ** (modified * exp);
		if (decrease) {
			const decreaseMultiplier = (multiplier - 1) / (fluctation - 1);
			multiplier = 1 - decreaseMultiplier;
		}
		return multiplier;
	};
	const res = Array(steps).fill(0).map((__, i) => avg * getMultiplierNr(toMulti, i / (steps - 1)));
	if (typeof numDecimals === 'number') {
		res.forEach((v, i) => {
			const decMulti = (10 ** numDecimals);
			const asLargeInt = (val) => Math.round(val * decMulti);
			let newV = asLargeInt(v);
			if (i) {
				const prev = asLargeInt(res[i - 1]);
				if (newV <= prev) {
					newV = prev + 1;
				}
			}
			res[i] = newV / decMulti;
		});
	}
	res[0] = 0;
	return res;
};

const summarizeNumbers = (obj, sums) => {
	const res = sums ? _.zipObject(sums, Array(sums.length).fill(0)) : {};
	const sum = (o) => {
		_.forOwn(o, (val, key) => {
			if (_.isNumber(val) && (!sums || key in res)) {
				res[key] = (res[key] || 0) + val;
			} else if (_.isPlainObject(val) || Array.isArray(val)) {
				sum(val);
			}
		});
	};
	sum(obj);
	return res;
};

const cpmOf = (revenue, impressions) => (revenue * 1000) / (impressions || 1);

module.exports = {
	UNLABELLED,
	convertMediaTypeId,
	convertDealTypeId,
	convertPaymentTypeId,
	toPx,
	parseMultipleDimensions,
	keyRenamedObj,
	dbEncodeKeys,
	dbDecodeKeys,
	limiter,
	varIsNonEmpty,
	getIdentifier,
	setByIdentifier,
	createCsv,
	asyncFuncCache,
	capitalize,
	asyncEvent,
	percentChange,
	pubUserReportOptions,
	arrayCallbacks,
	promiseAllSafe,
	onceEvent,
	swap,
	makeQs,
	withMembers,
	mergeUnits,
	base64Utf8Encode,
	base64Utf8Decode,
	distribute,
	summarizeNumbers,
	cpmOf,
};
