/* eslint-disable react/forbid-prop-types */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import { ActionButton } from '../ActionButton/ActionButton';
import Spinner from '../Spinner';
import DatePicker from '../DatePicker';
import DataTable from '../DataTable';
import DateUtils from '../../lib/dateUtils';
import ReactUtils from '../../lib/reactUtils';
import Select from '../Select';

const IMPORT_STATES = {
	notStarted: {},
	inProgress: {
		text: 'Import in progress..',
	},
	success: {
		color: 'success.main',
		text: 'Import successful!',
	},
	fail: {
		color: 'error.main',
		text: 'Import FAILED',
	},
};

const formatNumber = (n) => {
	if (typeof n !== 'number') {
		return n;
	}
	const asString = n.toString();
	const asFixed = n.toFixed(2);
	return asFixed.length < asString.length ? asFixed : asString;
};

class ExternalDataImport extends React.Component {
	constructor(props) {
		super(props);
		this.fld = ReactUtils.fld(this);
		const yesterday = DateUtils.yesterday();
		this.state = {
			..._.mapValues({
				from: ['fromDate', yesterday],
				to: ['toDate', yesterday],
				minDate: ['minDate', null],
				maxDate: ['maxDate', yesterday],
				fromHour: ['fromHour', 0],
				toHour: ['toHour', 23],
			}, ([field, defVal]) => (props[field] ? DateUtils.fullDay(props[field]) : defVal)),
			importState: IMPORT_STATES.notStarted,
		};
	}

	async importData() {
		const dd = (n) => (n < 10 ? '0' : '') + n;
		const fmtDate = (d) => `${d.getFullYear()}-${dd(d.getMonth() + 1)}-${dd(d.getDate())}`;
		const { model, importFn, hasHourSetting } = this.props;
		const {
			from, to, fromHour, toHour,
		} = this.state;
		this.setState({
			importState: IMPORT_STATES.inProgress,
			importResult: null,
			lastError: null,
		});
		try {
			let res;
			if (importFn) {
				res = await importFn(model, fmtDate(from), fmtDate(to), fromHour, toHour);
			} else {
				res = await model.importData({
					from: fmtDate(from),
					to: fmtDate(to),
					...(hasHourSetting ? { fromHour, toHour } : {}),
				});
			}
			this.setState({ importState: IMPORT_STATES.success, importResult: res });
		} catch (e) {
			this.setState({ importState: IMPORT_STATES.fail, lastError: e.error });
		}
	}

	render() {
		const {
			dataCols, hasHourSetting, description, beforeImportBtn,
		} = this.props;
		const {
			from, to, minDate, maxDate, importState, importResult, lastError,
		} = this.state;
		return (
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h2">
							{description}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<DatePicker
							floatingLabelText="First date to import"
							minDate={minDate}
							maxDate={to}
							name="from"
							value={from}
							autoOk
							onChange={(ev) => this.setState({ from: DateUtils.fullDay(ev.target.value) })}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<DatePicker
							floatingLabelText="Last date to import"
							minDate={from}
							maxDate={maxDate}
							name="to"
							value={to}
							autoOk
							onChange={(ev) => this.setState({ to: DateUtils.fullDay(ev.target.value) })}
							fullWidth
						/>
					</Grid>
					{hasHourSetting && (
						<Grid item xs={12}>
							<Grid container spacing={3}>
								<Grid item xs={3}>
									<Select
										{...this.fld('fromHour')}
										label="From hour"
										fullWidth
										items={_.range(0, 24).map((n) => ({ label: `${n < 10 ? '0' : ''}${n}`, value: n }))}
									/>
								</Grid>
								<Grid item xs={3}>
									<Select
										{...this.fld('toHour')}
										label="To hour"
										fullWidth
										items={_.range(0, 24).map((n) => ({ label: `${n < 10 ? '0' : ''}${n}`, value: n }))}
									/>
								</Grid>
							</Grid>
						</Grid>
					)}
					{beforeImportBtn && beforeImportBtn()}
					<Grid item xs={12}>
						{importState === IMPORT_STATES.inProgress ? <Spinner delay /> : (
							<ActionButton
								label="Import data"
								color="primary"
								onClick={() => this.importData()}
							/>
						)}
					</Grid>
					{importState !== IMPORT_STATES.notStarted && (
						<Box component={Grid} item xs={12} color={importState.color}>
							{importState.text}
							{lastError && ` - [${lastError.message}]`}
						</Box>
					)}
					{lastError && lastError.errorMail
						&& (
							<Grid item xs={12}>
								<a target="_blank" rel="noopener noreferrer" href={lastError.errorMail}>
									{lastError.errorMail}
								</a>
							</Grid>
						)}
					{importResult
						&& (
							<Grid item xs={12}>
								<DataTable
									showCheckboxes={false}
									selectableRows={false}
									identifier={(row) => row.nrOfDates}
									definitions={[
										{
											key: 'nrOfDates',
											title: 'Number of dates imported',
											format: (nr, row) => {
												const txt = nr ? `${nr}/${row.totalNrOfDates}` : 'No dates contained data';
												const color = nr === row.totalNrOfDates ? 'success.main' : (nr ? 'warning.main' : 'error.main');
												return <Box component="span" color={color}>{txt}</Box>;
											},
										},
									].concat(dataCols.map((dataCol) => ({ format: formatNumber, ...dataCol })))}
									data={[importResult]}
								/>
							</Grid>
						)}
				</Grid>
			</CardContent>
		);
	}
}

ExternalDataImport.propTypes = {
	model: PropTypes.object.isRequired,
	style: PropTypes.object,
	dataCols: PropTypes.array,
	importFn: PropTypes.func,
	hasHourSetting: PropTypes.bool,
	fromDate: PropTypes.any,
	toDate: PropTypes.any,
	minDate: PropTypes.any,
	maxDate: PropTypes.any,
	fromHour: PropTypes.any,
	toHour: PropTypes.any,
	description: PropTypes.string,
	beforeImportBtn: PropTypes.func,
};

ExternalDataImport.defaultProps = {
	style: {},
	dataCols: [
		{ key: 'totalRevenue', title: 'Total revenue' },
		{ key: 'totalImpressions', title: 'Total impressions' },
	],
	importFn: undefined,
	hasHourSetting: false,
	fromDate: undefined,
	toDate: undefined,
	minDate: undefined,
	maxDate: undefined,
	fromHour: undefined,
	toHour: undefined,
	description: 'Manually import data',
	beforeImportBtn: undefined,
};

export default ExternalDataImport;
