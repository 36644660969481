const _ = require('lodash');
const {
	idToObject,
	byIdMap,
	idToName,
	isDbIdType,
	ObjectTypeSettings,
} = require('./objectStore');

const ByType = {
	String: {
		defaultValueFn: ({ options, multiSelect }) => {
			if (multiSelect) {
				return [];
			}
			return options ? null : '';
		},
		getAsValid: (value, { hasOptions, optionType, multiSelect }) => {
			let val = value;
			if (multiSelect) {
				if (!Array.isArray(value)) {
					val = [];
				} else {
					val = value.filter((s) => typeof s === 'string');
				}
			}
			if (val && hasOptions && optionType) {
				// Filter away non-existing options
				if (Array.isArray(val)) { // multiselect
					return val.filter((id) => idToObject(id, optionType));
				} else if (!idToObject(val, optionType)) {
					return null;
				}
			}
			return val;
		},
	},
	Number: {
		defaultValue: 0,
	},
	JavaScript: {
		defaultValue: '',
	},
	Boolean: {
		defaultValue: false,
	},
	Array: {
		defaultValue: [],
		isContainer: true,
	},
	Object: {
		defaultValue: {},
		isContainer: true,
	},
	ByObject: {
		defaultValue: {},
		isContainer: true,
	},
	PrebidBuild: {
		defaultValue: '',
		isContainer: false,
	},
	HTML: {
		defaultValue: '',
	},
};

Object.values(ByType).forEach((obj) => {
	const typeStr = (v) => (Array.isArray(v) ? 'array' : typeof v);
	const expected = 'defaultValue' in obj && typeStr(obj.defaultValue);
	_.defaults(obj, {
		// Default-implementation of getAsValid() => return .defaultValue if the type is wrong
		getAsValid: (value) => {
			if (!expected || value === null || value === undefined) {
				return value;
			}
			return typeStr(value) === expected ? value : _.cloneDeep(obj.defaultValue);
		},
	});
});

const isStringType = (type) => type === 'String' || type === 'JavaScript' || type === 'HTML';

const requiredMissing = (fld, value) => {
	if (!fld.isRequired) {
		return false;
	}
	if (isStringType(fld.type)) {
		return !value;
	}
	if (fld.type === 'Number') {
		return Number.isNaN(parseFloat(value));
	}
	return false;
};

const TagFieldBase = (Base) => class extends Base {
	static get isMixinClass() { return true; }

	resetDefault() {
		const typeInfo = ByType[this.type];
		const { defaultValue, defaultValueFn } = typeInfo;
		this.defaultValue = _.cloneDeep(defaultValueFn ? defaultValueFn(this) : defaultValue);
	}

	generateDefault(dst, withMeta, opts = {}) {
		const typeInfo = ByType[this.type];
		let defVal = dst[this.name] || this.defaultValue;
		let defAppend;
		if (defVal === undefined) {
			const { defaultValue, defaultValueFn } = typeInfo;
			defVal = defaultValueFn ? defaultValueFn(this) : defaultValue;
		}
		defVal = _.cloneDeep(typeInfo.getAsValid(defVal, this));
		if (typeInfo.isContainer) {
			let subDst = defVal;
			let newOpts = opts;
			if (this.type === 'Array') {
				// We're creating a 'defAppend' object that can be used by GUI when appending new elements
				defAppend = (subDst = {});
				newOpts = { ...opts, inArray: true };
				defVal.forEach((obj) => {
					TagFieldBase.GenericTagField.generateDefaultValues(obj, this.fields, withMeta, newOpts);
				});
			}
			TagFieldBase.GenericTagField.generateDefaultValues(subDst, this.fields, withMeta, newOpts);
			if (this.type === 'ByObject') {
				// Create one object for each available option
				defVal = {};
				const excludeFn = ObjectTypeSettings[this.byObjectType]?.omitInTags;
				_.forOwn(byIdMap(this.byObjectType), (v, k) => {
					if (excludeFn?.(v)) {
						return;
					}
					const sub = _.cloneDeep(subDst);
					if (!withMeta) {
						defVal[k] = sub;
					} else {
						defVal[k] = {
							type: 'Object',
							description: idToName(k, this.byObjectType),
							name: k,
							value: sub,
							isOwn: false,
						};
					}
				});
			}
		}
		dst[this.name] = !withMeta ? defVal : {
			value: defVal,
			isOwn: false,
			defAppend,
			..._.pick(this, ['shouldHide']),
			..._.pick(this, Object.keys(TagFieldBase.BASE_SCHEMA_SPEC)),
		};
	}

	applyData(dst, dataObj, withMeta, isFinal, opts) {
		if (dataObj[this.name] === undefined) {
			return;
		}
		const typeInfo = ByType[this.type];
		const dataVal = typeInfo.getAsValid(dataObj[this.name], this);
		if (dataVal === undefined) {
			return;
		}
		if (typeInfo.isContainer) {
			const dstVal = withMeta ? dst[this.name].value : dst[this.name];
			if (this.type === 'Object') {
				if (dataVal instanceof Object) {
					TagFieldBase.GenericTagField.applyDataObject(dstVal, this.fields, dataVal, withMeta, isFinal, opts);
				}
			} else if (this.type === 'ByObject') {
				_.forOwn(dstVal, (v, k) => {
					if (dataVal[k]) {
						const subDst = withMeta ? v.value : v;
						if (withMeta && isFinal) {
							v.isOwn = true;
						}
						TagFieldBase.GenericTagField.applyDataObject(subDst, this.fields, dataVal[k], withMeta, isFinal, opts);
					}
				});
			} else if (this.type === 'Array') {
				if (dataVal instanceof Array) {
					dstVal.length = 0; // reset existing array
					dataVal.forEach((elm) => {
						const arrElm = {};
						TagFieldBase.GenericTagField.generateDefaultValues(arrElm, this.fields, withMeta);
						TagFieldBase.GenericTagField.applyDataObject(arrElm, this.fields, elm, withMeta, isFinal);
						dstVal.push(arrElm);
					});
				}
			}
		} else if (withMeta) {
			Object.assign(dst[this.name], { value: dataVal });
		} else {
			dst[this.name] = dataVal;
		}
		if (isFinal && withMeta) {
			Object.assign(dst[this.name], { isOwn: true });
		}
	}

	/** Remove "invalid" fields. This currently means 'ByObject' fields that are nested > 3 times, or
	 * 'ByObject' fields with the same .byObjectType that are nested. Main purpose is to not make it "easy" to create
	 * settings that will make us run out of memory (like creating 10 nested fields with 100 options each, etc). */
	cleanupInvalid() {
		const stack = [];
		const cleanup = (obj) => {
			stack.push(obj);
			const { type, fields } = obj;
			let keep = true;
			const { isContainer } = ByType[type] || {};
			obj.fields = isContainer ? fields.filter(cleanup) : [];
			if (type === 'ByObject') {
				const types = _.map(_.filter(stack, { type: 'ByObject' }), 'byObjectType');
				keep = types.length <= TagFieldBase.MaxByObjectNesting && _.uniq(types).length === types.length;
			}
			stack.pop();
			return keep;
		};
		return cleanup(this);
	}

	/** Iterate a settings object "deeply" along with corresponding fields. */
	static iterateFields(obj, fields, cb, withMeta) {
		const selfFn = TagFieldBase.GenericTagField.iterateFields;
		const { asPostCall } = cb;
		fields?.forEach((fld) => {
			const val = obj?.[fld.name];
			if (val === undefined) {
				return;
			}
			if (!asPostCall && cb(obj, fld, val) === false) {
				return; // Don't iterate any deeper here
			}
			const finalVal = withMeta ? val.value : val;
			const subCall = (subObj) => selfFn(subObj, fld.fields, cb, withMeta);
			if (fld.type === 'Array') {
				finalVal?.forEach(subCall);
			} else if (fld.type === 'Object') {
				subCall(finalVal);
			} else if (fld.type === 'ByObject') {
				if (withMeta) {
					_.forOwn(finalVal, (v) => {
						cb(finalVal, fld, v);
						subCall(v.value);
					});
				} else {
					_.forOwn(finalVal, subCall);
				}
			}
			if (asPostCall) {
				cb(obj, fld, val);
			}
		});
	}

	/** Cleanup 'ByOption' type objects that are not in idMap, if the are of model types. Also clean up 'String'
	 * fields using the same logic */
	static cleanupIds(dstObj, fields, idMap) {
		TagFieldBase.GenericTagField.iterateFields(dstObj, fields, (obj, fld, val) => {
			if (fld.includeNonPresent) {
				return;
			}
			if (fld.type === 'String') {
				if (fld.hasOptions && isDbIdType(fld.optionType)) {
					if (Array.isArray(val)) {
						obj[fld.name] = val.filter((id) => idMap[id]);
					} else if (!idMap[val]) {
						obj[fld.name] = null;
					}
				}
			} else if (fld.type === 'ByObject' && isDbIdType(fld.byObjectType)) {
				obj[fld.name] = _.pickBy(val, (__, id) => idMap[id]);
			}
		});
	}

	static generateDefaultValues(dst, fields, withMeta, opts = {}) {
		(fields || []).forEach((fld) => {
			if (!opts.onlyForOwnAsDefault || fld.ownAsDefault) {
				fld.generateDefault(dst, withMeta, opts);
				if (opts.infoDst && !opts.inArray) {
					let value = dst[fld.name];
					if (withMeta) {
						value = value.value;
					}
					if (requiredMissing(fld, value)) {
						opts.infoDst.hasMissingRequired = true;
					}
				}
			}
		});
	}

	static applyDataObject(dst, fields, dataObj, withMeta, isFinal, opts = {}) {
		(fields || []).forEach((fld) => {
			if (!opts.onlyForOwnAsDefault || fld.ownAsDefault) {
				fld.applyData(dst, dataObj, withMeta, isFinal, opts);
			}
		});
	}

	static clearOmittedDefaults(dst, fields) {
		TagFieldBase.GenericTagField.iterateFields(dst, fields, (obj, fld, val) => {
			if (!fld.omitWhenDefault) {
				return;
			}
			const { name } = fld;
			let doDelete;
			if (ByType[fld.type]?.isContainer) {
				doDelete = _.isEmpty(val);
			} else {
				const tmp = {};
				fld.generateDefault(tmp);
				doDelete = _.isEqual(val, tmp[name]);
			}
			if (doDelete) {
				delete obj[name];
			}
		});
	}

	static toRawData(data, inArray) {
		const res = {};
		_.forOwn(data, (val, key) => {
			if (!val.isOwn && !inArray) {
				return;
			}
			if (val.type === 'Object') {
				res[key] = this.toRawData(val.value, inArray);
			} else if (val.type === 'ByObject') {
				res[key] = _(val.value).pickBy((v) => v.isOwn || inArray)
					.mapValues((v) => this.toRawData(v.value, inArray)).value();
			} else if (val.type === 'Array') {
				res[key] = [];
				(val.value || []).forEach((elm) => {
					res[key].push(this.toRawData(elm, true));
				});
			} else if (val.type === 'Number') {
				const num = parseFloat(val.value);
				res[key] = Number.isNaN(num) ? null : num;
			} else {
				res[key] = val.value;
			}
		});
		return res;
	}
};

class GenericTagField extends TagFieldBase(class {}) {
	constructor(obj) {
		super();
		Object.assign(this, obj);
		this.fields = (this.fields || []).map((fld) => new GenericTagField(fld));
	}
}

TagFieldBase.GenericTagField = GenericTagField;
TagFieldBase.MaxByObjectNesting = 3; // Max number of ByObject containers

TagFieldBase.BASE_SCHEMA_SPEC = {
	name: { type: String, required: true },
	description: { type: String, required: true },
	defaultValue: Object, // will be changed to Schema.Types.Mixed in backend
	hasOptions: Boolean,
	optionType: {
		type: String,
		default: null,
	},
	byObjectType: {
		type: String,
		required: true,
		default: 'BaseSsp',
	},
	isRequired: Boolean,
	ownAsDefault: Boolean,
	displayInUi: Boolean,
	uiGroup: String,
	displayInHeader: Boolean,
	includeNonPresent: Boolean,
	multiSelect: Boolean,
	omitWhenDefault: Boolean,
	props: Object,
	options: [{
		name: { type: String, required: true },
		label: { type: String, required: true },
	}],
	type: {
		type: String,
		required: true,
		default: 'String',
		enum: ['String', 'JavaScript', 'Boolean', 'Array', 'Number', 'Object', 'PrebidBuild', 'HTML', 'ByObject'],
	},
};

module.exports = TagFieldBase;
