import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { formatCash } from '../../lib/numberUtils';

function TrendBox({ keyProp, current, historical, getTrendInformation }) {
	const diffToString = (v1, v2) => {
		const toValidNumber = (value) => {
			const num = Number(value);
			return Number.isNaN(num) ? 0 : num;
		};
		const diff = toValidNumber(v1) - toValidNumber(v2);
		return diff !== 0 ? `(${formatCash(diff)})` : '';
	};

	const { formatted, isPercentage, color } = getTrendInformation({
		noIcon: true,
		key: keyProp,
		current,
		historical,
	});
	const multiLineStyle = !isPercentage ? { marginTop: 1, lineHeight: 0.5 } : {};
	const width = !isPercentage ? 60 : 72; // Based on the maxium width of the diff text
	return (
		<Box sx={{ width, textAlign: 'right' }}>
			<Typography sx={{ fontSize: 'small', color, ...multiLineStyle }} variant="inherit">
				{formatted}
			</Typography>
			{!isPercentage && (
				<Typography sx={{ fontSize: 'smaller' }} variant="inherit">
					{diffToString(current, historical)}
				</Typography>
			)}
		</Box>
	);
}

TrendBox.propTypes = {
	keyProp: PropTypes.string.isRequired,
	current: PropTypes.number.isRequired,
	historical: PropTypes.number,
	getTrendInformation: PropTypes.func.isRequired,
};

TrendBox.defaultProps = {
	historical: undefined,
};

export default TrendBox;
