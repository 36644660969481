exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".horizontal__src-components-MenuDivider-styles__LIfto {\n\tmargin: 8px 0 !important;\n}\n\n.vertical__src-components-MenuDivider-styles__3j-SJ {\n\theight: 60% !important;\n}\n", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/worker/temp/relevant-yield/relevant-frontend/src/components/MenuDivider/styles.css"],"names":[],"mappings":"AAAA;CACC,yBAAwB;CACzB;;AAEA;CACC,uBAAsB;CACvB","file":"styles.css","sourcesContent":[".horizontal {\n\tmargin: 8px 0 !important;\n}\n\n.vertical {\n\theight: 60% !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"horizontal": "horizontal__src-components-MenuDivider-styles__LIfto",
	"vertical": "vertical__src-components-MenuDivider-styles__3j-SJ"
};