import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FieldDataObject from './fieldDataObject';
import { ActionButton } from '../ActionButton/ActionButton';
import TextEditor from './textEditor';
import PrebidBuildFormModal from '../PrebidBuild/PrebidBuildFormModal';
import PrebidBuildManager from '../PrebidBuild/PrebidBuildManager';

class TagDataEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderAudienceTag() {
		const { info } = this.props;
		const tagStyle = { backgroundColor: 'lightgray', borderRadius: '10px', padding: '10px' };
		return (
			<Card>
				<CardContent>
					<Typography variant="h4">
						Info
					</Typography>
					Place on site:&nbsp;&nbsp;
					<span style={tagStyle}>
						{'<script type="application/javascript" async src="'}
						<a href={info.cdnUrl} target="_blank" rel="noreferrer">{info.cdnUrl}</a>
						{'"></script>'}
					</span>
				</CardContent>
			</Card>
		);
	}

	renderProgrammaticTag() {
		const { info } = this.props;
		const tag =
`<!-- This script can be loaded at any time, including after initial prebid auction / ad requests -->
<script async src="${info.cdnUrl}"></script>`;
		return (
			<Card>
				<CardContent style={{ paddingBottom: 8 }}>
					<Typography variant="h2">
						Analytics tag, for analytics-only use
					</Typography>
					<TextEditor
						width="100%"
						height="40px"
						name="programmaticTag"
						readOnly
						showGutter={false}
						value={tag}
					/>
				</CardContent>
			</Card>
		);
	}

	render() {
		const {
			model, field, onCancel, submit, info, type, match, location,
		} = this.props;
		return (
			<>
				{info.isLeaf
					&& (
						<Grid item xs={12} style={{ padding: '0 0 24px 0' }}>
							{ type === 'programmatic' ? this.renderProgrammaticTag() : this.renderAudienceTag() }
						</Grid>
					)}
				<FieldDataObject model={model} field={field} />
				<Grid item>
					<ActionButton
						label="Ok"
						color="primary"
						onClick={submit}
					/>
				</Grid>
				<Grid item>
					<ActionButton
						label="Cancel"
						onClick={() => onCancel()}
						variant="text"
						color="primary"
					/>
				</Grid>
				<Route
					exact
					path={`${match.path}/add-prebid-build`}
					render={(p) => (
						<PrebidBuildFormModal
							match={match}
							location={location}
						/>
					)}
				/>
				<Route
					exact
					path={`${match.path}/manage-prebid-builds`}
					render={(p) => <PrebidBuildManager match={match} location={location} />}
				/>
			</>
		);
	}
}

TagDataEditor.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
	info: PropTypes.object.isRequired,
	type: PropTypes.oneOf(['programmatic', 'audience']).isRequired,
};

TagDataEditor.defaultProps = {
};

export default TagDataEditor;
