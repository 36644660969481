import _ from 'lodash';
import AdformLogo from '../assets/Adform.png';
import WideOrbitLogo from '../assets/WideOrbit.jpg';
import AdxLogo from '../assets/Google_Ad_Manager_logo.jpg';
import OpenXLogo from '../assets/OpenX_logo.png';
import ImproveLogo from '../assets/Improve_Digital_Logo.png';
import PubmaticLogo from '../assets/Pubmatic_Logo.jpg';
import DistrictmLogo from '../assets/DistrictM_logo.png';
import DFPLogo from '../assets/Google_Ad_Manager_logo.jpg';
import SmartRtbLogo from '../assets/Smart_RTB_logo.png';
import CriteoLogo from '../assets/Criteo_Logo.png';
import IndexExchangeLogo from '../assets/Index_Exchange_Logo.svg';
import DemoLogo from '../assets/Relevant_yield_logo_topbar.png';
import WidespaceLogo from '../assets/Widespace_logo.png';
import JustPremiumLogo from '../assets/JustPremium.jpg';
import GmailLogo from '../assets/gmail.png';
import AdnuntiusLogo from '../assets/Adnuntius_logo.svg';
import RecognifiedLogo from '../assets/recognified_logo.png';
import AmazonLogo from '../assets/amazon_logo.svg';
import TeadsLogo from '../assets/teads_logo.png';
import YieldLabLogo from '../assets/yieldlab_logo.png';
import VlybyLogo from '../assets/vlyby_logo.svg';
import AdDefendLogo from '../assets/addefend_logo.svg';
import PlistaLogo from '../assets/plista_logo.png';
import TaboolaLogo from '../assets/taboola_logo.png';
import SeedtagLogo from '../assets/seedtag_logo.png';
import UnrulyLogo from '../assets/unruly_logo.png';
import RevContentLogo from '../assets/revcontent_logo.png';
import ConnectAdLogo from '../assets/connectad_logo.png';
import SmartClipLogo from '../assets/smartclip_logo.png';
import HolidLogo from '../assets/holid_logo.png';
import XandrLogo from '../assets/xandr_logo.png';
import MagniteLogo from '../assets/magnite_logo.png';
import OutbrainLogo from '../assets/outbrain_logo.png';
import OneTagLogo from '../assets/onetag_logo.png';
import RichAudienceLogo from '../assets/richaudience.png';
import AdagioLogo from '../assets/adagio_logo.png';
import SynacorMediaLogo from '../assets/synacor-media-logo.png';
import NextMillenniumLogo from '../assets/nextMillennium_logo.svg';
import DailymotionLogo from '../assets/dailymotion_logo.png';
import SovrnLogo from '../assets/sovrn-logo.png';
import ConnatixLogo from '../assets/connatix_logo.png';
import AdyoulikeLogo from '../assets/adyoulike.png';

const SspLogos = [
	{
		type: 'AdformSsp',
		logo: AdformLogo,
	},
	{
		type: 'AdmetaSsp',
		logo: WideOrbitLogo,
	},
	{
		type: 'AdnuntiusSsp',
		logo: AdnuntiusLogo,
	},
	{
		type: 'AdxSsp',
		logo: AdxLogo,
	},
	{
		type: 'AppnexusSsp',
		logo: XandrLogo,
	},
	{
		type: 'CriteoSsp',
		logo: CriteoLogo,
	},
	{
		type: 'ImproveSsp',
		logo: ImproveLogo,
	},
	{
		type: 'IndexSsp',
		logo: IndexExchangeLogo,
	},
	{
		type: 'PubmaticSsp',
		logo: PubmaticLogo,
	},
	{
		type: 'RelevantSsp',
		logo: DemoLogo,
	},
	{
		type: 'RubiconSsp',
		logo: MagniteLogo,
	},
	{
		type: 'RtbPlusSsp',
		logo: SmartRtbLogo,
	},
	{
		type: 'OpenXSsp',
		logo: OpenXLogo,
	},
	{
		type: 'DFPSsp',
		logo: DFPLogo,
	},
	{
		type: 'DemoSsp',
		logo: DemoLogo,
	},
	{
		type: 'DistrictmSsp',
		logo: DistrictmLogo,
	},
	{
		type: 'WidespaceSsp',
		logo: WidespaceLogo,
	},
	{
		type: 'JustPremiumSsp',
		logo: JustPremiumLogo,
	},
	{
		type: 'MailImportSsp',
		logo: GmailLogo,
	},
	{
		type: 'RecognifiedSsp',
		logo: RecognifiedLogo,
	},
	{
		type: 'AmazonSsp',
		logo: AmazonLogo,
	},
	{
		type: 'TeadsSsp',
		logo: TeadsLogo,
	},
	{
		type: 'YieldLabSsp',
		logo: YieldLabLogo,
	},
	{
		type: 'VlybySsp',
		logo: VlybyLogo,
	},
	{
		type: 'AdDefendSsp',
		logo: AdDefendLogo,
	},
	{
		type: 'PlistaSsp',
		logo: PlistaLogo,
	},
	{
		type: 'TaboolaSsp',
		logo: TaboolaLogo,
	},
	{
		type: 'SeedTagSsp',
		logo: SeedtagLogo,
	},
	{
		type: 'UnrulySsp',
		logo: UnrulyLogo,
	},
	{
		type: 'RevContentSsp',
		logo: RevContentLogo,
	},
	{
		type: 'ConnectAdSsp',
		logo: ConnectAdLogo,
	},
	{
		type: 'SmartClipSsp',
		logo: SmartClipLogo,
	},
	{
		type: 'OutbrainSsp',
		logo: OutbrainLogo,
	},
	{
		type: 'OneTagSsp',
		logo: OneTagLogo,
	},
	{
		type: 'RichAudienceSsp',
		logo: RichAudienceLogo,
	},
	{
		type: 'AdagioSsp',
		logo: AdagioLogo,
	},
	{
		type: 'SynacorMediaSsp',
		logo: SynacorMediaLogo,
	},
	{
		type: 'NextMillenniumSsp',
		logo: NextMillenniumLogo,
	},
	{
		type: 'DailymotionSsp',
		logo: DailymotionLogo,
	},
	{
		type: 'SovrnSsp',
		logo: SovrnLogo,
	},
	{
		type: 'ConnatixSsp',
		logo: ConnatixLogo,
	},
	{
		type: 'HolidSsp',
		logo: HolidLogo,
	},
	{
		type: 'AdyoulikeSsp',
		logo: AdyoulikeLogo,
	},
];

export const sspLogo = (ssp) => {
	const sspData = () => _.find(SspLogos, { type: ssp.sspStyleFrom || ssp.type });
	return ssp.customLogoPath || sspData()?.logo || ssp.logoUrl || '';
};

export const fallbackSspLogo = DemoLogo;
