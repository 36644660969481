import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import _ from 'lodash';
import TextField from '../TextField';
import styles from './styles.css';
import SystemData from '../../lib/systemData';
import { sspLogo, fallbackSspLogo } from '../../lib/sspLogos';

import Base from '../../layouts/Base';
import { CenterContent } from '../Wrappers';
import PopupSelector from '../PopupSelector';
import CheckboxGroup from '../CheckboxGroup';
import SiteSelect from '../SiteSelect';
import Select from '../Select';
import { ConfirmDialog } from '../ConfirmDialog';
import ImageWithFallback from '../ImageWithFallback';

const MediaTypes = [
	{ value: 'mixed', label: 'Mixed' },
	{ value: 'banner', label: 'Banner' },
	{ value: 'video', label: 'Video' },
	{ value: 'native', label: 'Native' },
];

class SSPForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { OBSCURE_NAMING_ENABLED, hbmEnabled } = SystemData.genericData;
		const {
			field, form, object, placementNode,
		} = this.props;
		const { placementType } = placementNode;
		const {
			ssp, publisherNode, parentNode, shadows,
		} = object;
		const canSelectMediaType = placementType && placementType.mediaType === 'banner';
		const logo = sspLogo(ssp);
		const isShadow = object.type === 'SspShadowNode';
		return (
			<Card>
				<CardContent>
					<Grid container spacing={3} alignItems="center">
						<Grid item xs={11}>
							<Grid container spacing={3}>
								<Grid item xs={3}>
									<Grid container spacing={0}>
										<Grid item xs={12}>
											<span style={{ color: 'gray', fontWeight: 'bold' }}>{ssp.name}</span>
										</Grid>
										<Grid item xs={12}>
											<ImageWithFallback
												src={logo}
												fallback={fallbackSspLogo}
												alt="logo"
												className={styles.sspLogo}
												style={{
													...(isShadow && { filter: 'grayscale(100%)' }),
													pointerEvents: 'none',
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={9}>
									<CenterContent vertical>
										{OBSCURE_NAMING_ENABLED && <i>Not visible in demo mode</i>}
										{!OBSCURE_NAMING_ENABLED && (isShadow ? (
											<span>
												<span style={{ color: 'gray', fontWeight: 'bold' }}>
													COPY FROM:&nbsp;&nbsp;
												</span>
												{object.sspPlac.sspPlacementId}
												<div style={{ color: 'gray', fontSize: 'small' }}>
													{object.sspPlacNode.placementNode.name}
												</div>
											</span>
										) : (
											<TextField
												{...field('id')}
												label="ID"
												required
												fullWidth
												isolated={!shadows.length}
											/>
										))}
									</CenterContent>
								</Grid>
								<Grid item xs={12} style={{ padding: '4px 12px' }}>
									{object.isPbjsBidder && hbmEnabled && (
										<Grid container spacing={3}>
											<Grid item>
												<Box component="div" pl={1.5}>
													<PopupSelector
														title="Prebid configs"
														form={form}
														selected={object.prebidConfigs}
														content={(popup) => (
															<CheckboxGroup
																items={object.publisherNode.children.map((siteNode) => (
																	siteNode.obj.prebidConfigs.map((cfg) => ({
																		id: cfg.id,
																		text: `${siteNode.name} - ${cfg.name}`,
																	}))
																)).flat()}
																selected={object.prebidConfigs.map(({ id }) => id)}
																onChange={(items) => popup.formUpdate(() => {
																	object.obj.pbCfgs = items;
																})}
															/>
														)}
													/>
												</Box>
											</Grid>
											{!isShadow && (
												<>
													<Grid item>
														<PopupSelector
															title="Other placements"
															selected={object.shadows}
															size="sm"
															noLinkNbsp
															form={form}
															content={(popup) => (
																<SiteSelect
																	isPlacements
																	expanded
																	fullWidth
																	selected={object.shadows.map((shadow) => shadow.placementId)}
																	getPublishersFn={() => [publisherNode.obj]}
																	onChange={(placementIds) => popup.formUpdate(() => {
																		const shadows = object.shadows.filter((shadow) => _.includes(placementIds, shadow.placementId));
																		const existing = _.keyBy(shadows, 'placementId');
																		placementIds.filter((id) => !existing[id]).forEach((placementId) => {
																			const placement = publisherNode.byId[placementId];
																			const pbCfgs = placement.getDefaultPrebidConfigs().map(({ id }) => id);
																			if (placementId !== parentNode.id) {
																				shadows.push(new SystemData.models.ShadowSspPlacement({
																					placementId,
																					pbCfgs,
																				}));
																			}
																		});
																		object.obj.shadows = shadows;
																	})}
																/>

															)}
														/>
													</Grid>
													<Grid item>
														{canSelectMediaType && (
															<Select
																style={{ minWidth: 150 }}
																label="Limit media type"
																isolated
																nonSelected={`Auto (${_.find(MediaTypes, {
																	value: object.defaultMediaTypeLimiter,
																}).label})`}
																{...field('mediaTypeLimiter')}
																items={MediaTypes}
															/>
														)}
													</Grid>
												</>
											)}
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={1}>
							<Tooltip title="Delete SSP">
								<IconButton
									style={{ padding: 0 }}
									onClick={async () => {
										const ok = await Base.renderGlobal((closeFn) => (
											<ConfirmDialog
												open
												text="Do you want to delete this SSP placement"
												onAny={closeFn}
											/>
										));
										if (ok) {
											form.update(() => object.removeNode());
										}
									}}
									size="large"
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	}
}

SSPForm.propTypes = {
	object: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	placementNode: PropTypes.object.isRequired,
};

SSPForm.defaultProps = {

};

export default SSPForm;
