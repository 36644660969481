exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".container__src-components-CollapseHeader-styles__vkj75 {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}\n\n.button__src-components-CollapseHeader-styles__381tp {\n    padding: 0;\n    border: 0;\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    background: transparent;\n    text-align: left;\n}\n\n.mainClickable__src-components-CollapseHeader-styles__13SU3 {\n    flex-grow: 1;\n}\n", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/worker/temp/relevant-yield/relevant-frontend/src/components/CollapseHeader/styles.css"],"names":[],"mappings":"AAAA;IACI,cAAa;IACb,+BAA8B;IAC9B,oBAAmB;IACnB,YAAW;CACf;;AAEA;IACI,WAAU;IACV,UAAS;IACT,yBAAwB;IACxB,sBAAqB;IACrB,iBAAgB;IAChB,wBAAuB;IACvB,iBAAgB;CACpB;;AAEA;IACI,aAAY;CAChB","file":"styles.css","sourcesContent":[".container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}\n\n.button {\n    padding: 0;\n    border: 0;\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    background: transparent;\n    text-align: left;\n}\n\n.mainClickable {\n    flex-grow: 1;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "container__src-components-CollapseHeader-styles__vkj75",
	"button": "button__src-components-CollapseHeader-styles__381tp",
	"mainClickable": "mainClickable__src-components-CollapseHeader-styles__13SU3"
};