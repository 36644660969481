/* eslint-disable react/jsx-one-expression-per-line, react/forbid-prop-types, react/no-unstable-nested-components */
import React from 'react';
import _ from 'lodash';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import TextField from '../TextField';
import Base from '../../layouts/Base';
import { PopupForm, wrapFnComponent } from '../Wrappers';
import Snippets from './snippets';
import { triggerToast } from '../Toast/misc';

const popup = (initModel, renderFn) => Base.renderGlobal((done) => (
	<PopupForm
		title="Google account login"
		forceExpanded
		size="sm"
		getModel={() => initModel}
		content={(p) => (
			<Grid container spacing={3}>
				<Grid item xs={12}>{Snippets.googleLoginInfo}</Grid>
				{renderFn(p)}
			</Grid>
		)}
		onApplyChanges={({ model }) => done(model)}
		onCancel={done}
	/>
));

/** Handles interactions where the backend requests information such as verification code, etc */
const interactions = {
	username: (p) => popup(p, ({ field }) => (
		<Grid item xs={12}>
			<TextField
				email
				required
				fullWidth
				label="Google user (email)"
				{...field('username')}
			/>
		</Grid>
	)),
	password: (p) => popup(p, ({ field, model: { username } }) => (
		<>
			<Grid item xs={12}>
				<Alert severity="warning">
					{Snippets.passwordProbablyWrong}
				</Alert>
			</Grid>
			<Grid item xs={12}>
				<TextField
					password
					required
					fullWidth
					label={`Password for user ${username}`}
					{...field('password')}
				/>
			</Grid>
		</>
	)),
	verificationEmail: (p) => popup(p, ({ field, model: { username } }) => (
		<Grid item xs={12}>
			<TextField
				email
				required
				fullWidth
				label={`Confirm the recovery email for user ${username}`}
				{...field('verificationEmail')}
			/>
		</Grid>
	)),
	phoneNumber: (p) => popup(p, ({ field }) => (
		<>
			<Grid item xs={12}>
				<Alert severity="info">
					{Snippets.phoneNumberDescription}
				</Alert>
			</Grid>
			<Grid item xs={12}>
				<TextField
					required
					fullWidth
					label="Phone number for verification code"
					{...field('phoneNumber')}
				/>
			</Grid>
		</>
	)),
	smsVerificationCode: (p) => popup(p, ({ field }) => (
		<>
			<Grid item xs={12}>
				<TextField
					required
					fullWidth
					label="Verification code received by SMS"
					{...field('smsVerificationCode')}
				/>
			</Grid>
		</>
	)),
};

/** Create a "message-channel", namely the handlers for different message from the server.
  * This includes the "progress" messages that we're displaying inside a <Toast> */
const createMsgChannel = ({ maxProgressMsgs = 8 } = {}) => {
	let progresses = [{ msg: 'Starting progress...' }];
	const ui = {};
	const msgChannel = {
		...interactions,
		progress: (settings) => {
			progresses.push(settings);
			if (progresses.length > maxProgressMsgs) {
				progresses = [{ msg: '...' }, ...progresses.slice(-maxProgressMsgs)];
			}
			ui.box?.update();
		},
		onChannelClosed({ error }) {
			this.progress(error ? { msg: 'Failed...', isError: true } : { msg: 'Done' });
			setTimeout(() => ui.closeFn?.(), 5000);
		},
	};
	const render = () => ( // Renders the list inside the <Toast>
		<>
			{progresses.map(({ msg, isError }, idx) => (
				<div
					style={isError && { fontWeight: 'bold', color: 'red' }}
					key={idx.toString()}
				>
					{msg}
				</div>
			))}
		</>
	);
	const Renderer = wrapFnComponent((p, box) => Object.assign(ui, p, { box }) && render());
	triggerToast({
		message: (closeFn) => <Renderer closeFn={closeFn} />,
		status: 'info',
	});
	return { msgChannel };
};

export default createMsgChannel;
