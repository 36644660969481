/* eslint-disable import/prefer-default-export */
const locales = 'en-EN';

const float = new Intl.NumberFormat(locales, {
	style: 'decimal',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

const int = new Intl.NumberFormat(locales, {
	style: 'decimal',
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const fmtNum = (number) => {
	if (number === 0) {
		return 0;
	}
	if (Math.round(number) === number) {
		return int.format(number);
	}
	return float.format(number);
};

export const fmtNumCsv = (number) => number.toFixed(2);

const cashFormatter = Intl.NumberFormat('en-EN', {
	notation: 'compact',
	maximumFractionDigits: 0, // For large numbers, no decimal places
});

const cashFormatterSmall = Intl.NumberFormat('en-EN', {
	notation: 'compact',
	maximumFractionDigits: 3,
});

export const formatCash = (n) => {
	// Use cashFormatterSmall for small numbers and values that need decimals (e.g., less than 1)
	if (Math.abs(n) < 1) {
		return cashFormatterSmall.format(n);
	}
	// Otherwise, use the regular formatter
	return cashFormatter.format(n);
};
