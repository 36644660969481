exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".input__src-components-UploadButton-styles__22Twa {\n    display: none;\n}\n", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/worker/temp/relevant-yield/relevant-frontend/src/components/UploadButton/styles.css"],"names":[],"mappings":"AAAA;IACI,cAAa;CACjB","file":"styles.css","sourcesContent":[".input {\n    display: none;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"input": "input__src-components-UploadButton-styles__22Twa"
};