exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".container__src-components-OperationWrapper-styles__2KBTi {\n\n}\n\n.progressContainer__src-components-OperationWrapper-styles__ZzlUd {\n\tbackground-color: rgb(255, 255, 255);\n\tborder-radius: 2px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\theight: 100%;\n\toverflow: hidden;\n}\n", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/worker/temp/relevant-yield/relevant-frontend/src/components/OperationWrapper/styles.css"],"names":[],"mappings":"AAAA;;CAEA;;AAEA;CACC,qCAAoC;CACpC,mBAAkB;CAClB,cAAa;CACb,wBAAuB;CACvB,oBAAmB;CACnB,aAAY;CACZ,iBAAgB;CACjB","file":"styles.css","sourcesContent":[".container {\n\n}\n\n.progressContainer {\n\tbackground-color: rgb(255, 255, 255);\n\tborder-radius: 2px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\theight: 100%;\n\toverflow: hidden;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "container__src-components-OperationWrapper-styles__2KBTi",
	"progressContainer": "progressContainer__src-components-OperationWrapper-styles__ZzlUd"
};