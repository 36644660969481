import React, { forwardRef } from 'react';
import { Grid,
	Box,
	TextField as MuiTextField } from '@mui/material';
import _ from 'lodash';
import { idToName, idToObject, isDbIdType, objectArray, CustomObjectNames } from 'relevant-shared/objects/objectStore';
import { selectOptionsProps, typeToDescription } from './utils';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import { FormOf, Scope, PopupForm, wrapFnComponent } from '../Wrappers';
import Select from '../Select';
import Form from '../../containers/Form';
import SiteSelect from '../SiteSelect';
import PopupSelector from '../PopupSelector';
import CheckboxGroup from '../CheckboxGroup';
import FieldArray from '../FieldArray';
import CountrySelect from '../CountrySelect';

/** Notice: because of the 'multiSelect' option, fields of type 'String' can actually be arrays of strings. */

/** Short-form used in "headers" when 'displayInHeader' is set. Only used for placements now */
const stringDesc = ({
	value, options, hasOptions, optionType,
}) => {
	const val = value.value;
	const NONE = '(none)';
	const descOf = (v) => {
		if (v === null || v === undefined) {
			return NONE;
		}
		if (hasOptions) {
			if (optionType) {
				return idToName(v, optionType) || NONE;
			} else {
				return (options.find(({ name }) => name === v))?.label || `${v} [Unknown]`;
			}
		}
		return v;
	};
	if (Array.isArray(val)) {
		if (val.length === 1) {
			return descOf(val[0]);
		} else if (val.length) {
			return `[${val.length} selected]`;
		} else {
			return NONE;
		}
	}
	return descOf(val);
};

/** .hasOptions is true but there is no .optionType nor .multiSelect (normal dropdown of options) */
const renderStandardOptions = ({
	name,
	label,
	field,
	model,
	required,
	inDisabled,
}) => {
	let extraOption = [];
	const curr = model[name];
	if (curr && !model.options.find(({ name: n }) => n === curr)) {
		extraOption = [{ name: curr, label: `${curr} [Unknown]` }];
	}
	return (
		<Select
			{...field(name)}
			disabled={inDisabled}
			hideLabelIfEmpty
			label={label}
			style={{ minWidth: 200 }}
			required={required}
			fullWidth
			nonSelected={required && curr ? null : '(none)'}
			items={[...extraOption, ...model.options].map((opt) => ({
				label: opt.label,
				value: opt.name,
			}))}
		/>
	);
};

const renderOptionsEdit = (p) => (
	<FieldArray
		model={p.model}
		field={p.field}
		name="options"
		update={() => p.form.update()}
		createNew={() => ({	name: '', label: '' })}
		render={(fld) => (
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<TextField
						{...(fld('name'))}
						label="Name"
						fullWidth
						required
						margin="normal"
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						{...(fld('label'))}
						label="Label"
						fullWidth
						required
						margin="normal"
					/>
				</Grid>
			</Grid>
		)}
	/>
);

/** name and value are normally set on the input-element, but as we'll use a TextField below without any input
 * we need to provide them in a <div> here instead so that form validation will work */
const TextFieldWrap = wrapFnComponent((props) => (
	<div
		ref={(elm) => {
			if (elm) {
				elm.value = props.value;
			}
		}}
		name={props.name}
	>
		<MuiTextField
			{..._.omit(props, Form.metaFields)}
		/>
	</div>
));

/** All combinations where selection is done via PopupSelector (instead of TextField/Select) */
const renderObjectSelect = (p) => {
	const {
		model, name, required, label, field, inDisabled,
	} = p;
	const {
		optionType, multiSelect, options, hasOptions,
	} = model;
	const form = Form.fromField(field);
	const prev = model[name];

	const selectOptions = ({ model: m, popup }) => ({
		selected: m.selected,
		onChange: (items) => popup.update(() => {
			if (inDisabled) {
				return;
			}
			let newItems = items;
			if (!multiSelect && items.length >= 2) { // "Force" single select
				newItems = _.without(items, ...m.selected);
				if (newItems.length >= 2) {
					newItems = [];
				}
			}
			m.selected = newItems;
		}),
	});

	// Publisher/Site/Placement
	const siteSelect = (extraProps, settings) => (
		<SiteSelect
			{...selectOptions(settings)}
			{...extraProps}
			selectorType="NoSelector"
			getPublishersFn={() => objectArray('Publisher')}
			useSearchFilter
			singleSelect={!multiSelect}
		/>
	);

	// SSP/Adserver
	const systemSelect = (settings) => (
		<CheckboxGroup
			items={objectArray(optionType, true).map(({ id }) => ({ id, text: idToName(id, optionType) }))}
			{...selectOptions(settings)}
		/>
	);

	// Multi-select of normal options (no .optionType)
	const optionSelect = (settings) => (
		<CheckboxGroup
			items={options.map((opt) => ({ id: opt.name, text: opt.label }))}
			{...selectOptions(settings)}
		/>
	);
	optionSelect.idToName = (id) => _.find(options, { name: id })?.label ?? id;

	// Custom object type select
	const customSelect = (settings) => (
		<CheckboxGroup
			items={objectArray(optionType).map((opt) => ({ id: opt.id, text: opt.name }))}
			{...selectOptions(settings)}
		/>
	);

	// Country
	const countrySelect = (settings) => (
		<CountrySelect
			{...selectOptions(settings)}
			includeTree
		/>
	);

	// .multiSelect without .hasOptions (list of "free text" fields)
	const freeSelect = (pp) => (
		<FieldArray
			model={pp.model}
			field={pp.field}
			form={pp.form}
			name="selected"
			update={() => pp.form.update()}
			createNew={() => ({ str: '' })}
			beforeHeader={(fld) => (
				<TextField
					disabled={inDisabled}
					isolated
					label=""
					fullWidth
					{...fld('str')}
				/>
			)}
		/>
	);
	freeSelect.asObjects = true;
	freeSelect.idToName = (s) => s;

	const renderers = {
		Publisher: (settings) => siteSelect(null, settings),
		Site: (settings) => siteSelect({ isSspSites: true }, settings),
		Placement: (settings) => siteSelect({ isPlacements: true }, settings),
		BaseAdserver: systemSelect,
		BaseSsp: systemSelect,
		Country: countrySelect,
		...Object.fromEntries(CustomObjectNames.map((type) => ([type, customSelect]))),
	};
	const renderer = renderers[optionType] || (hasOptions ? optionSelect : freeSelect);
	const { asObjects } = renderer;

	// previously select options (one-element array in case of no .multiSelect)
	const prevAsArr = () => (Array.isArray(prev) ? [...prev] : [prev]).filter((id) => (
		optionType ? idToObject(id, optionType) : renderer.idToName(id) !== undefined
	));

	return (
		<TextFieldWrap
			label={label}
			{...field(name)}
			style={{ minWidth: 200 }}
			disabled={inDisabled}
			required={required}
			fullWidth
			InputLabelProps={{ shrink: true }}
			InputProps={{
				inputComponent: forwardRef((props, ref) => (
					<div ref={ref}>
						<PopupForm
							title={typeToDescription(optionType || 'Option', true)}
							nonSelectedString="(none)"
							size="md"
							linkStyle={{ margin: 18, ...(inDisabled && { opacity: 0.5 }) }}
							selected={prevAsArr()}
							okDisabled={inDisabled}
							getModel={() => ({ selected: prevAsArr().map((str) => (asObjects ? { str } : str)) })}
							getSingleTitle={(id) => (renderer.idToName || idToName)(id, optionType)}
							content={renderer}
							onApplyChanges={(settings) => form.update(() => {
								const selected = settings.model.selected.map((elm) => (asObjects ? elm.str : elm));
								model[name] = multiSelect ? selected : selected[0];
								// eslint-disable-next-line react/prop-types
								props.onChange({ target: { name: props.name, value: model[name] } }, props);
							})}
						/>
					</div>
				)),
			}}
		/>
	);
};

// Everything that's not just a <TextField>
const renderNonStandardString = (p) => {
	const { model } = p;
	const { hasOptions, optionType, multiSelect } = model;
	if (hasOptions && !optionType && !multiSelect) {
		return renderStandardOptions(p);
	}
	return renderObjectSelect(p);
};

// Not just a <TextField>
const isNonStandard = (model) => model.multiSelect || model.hasOptions;

const renderEdit = (props) => {
	const {
		name, label, field, model,
	} = props;
	const { hasOptions, optionType } = model;
	const nonStandard = isNonStandard(model);
	return (
		<Scope
			content={(scope) => {
				const tmpObj = _.cloneDeep(model);
				return (
					<Box mt={2}>
						<Grid container spacing={3}>
							<Grid item xs={nonStandard ? undefined : 6}>
								{nonStandard ? renderNonStandardString(props) : (
									<TextField {...field(name)} label={label} fullWidth margin="none" />
								)}
							</Grid>
							<Grid item>
								<Checkbox
									label="Options"
									onFieldUpdate={() => model.resetDefault()}
									textFieldAlign
									{...field('hasOptions')}
								/>
							</Grid>
							{model.hasOptions && (
								<>
									<Grid item xs={3}>
										<Select
											{...selectOptionsProps('optionType', { field })}
											onFieldUpdate={() => model.resetDefault()}
											label="Options type"
											fullWidth
											nonSelected="Custom options"
										/>
									</Grid>
									{!model.optionType && (
										<Grid item>
											<FormOf
												model={tmpObj}
												content={(p) => (
													<PopupSelector
														title="Edit options"
														size="md"
														textFieldAlign
														form={p.form}
														selected={model.options}
														onCancel={() => scope.update()}
														allowErrorsIfCancel
														onStateUpdate={({ expanded }) => {
															if (expanded) {
																// To clear possible non-saved validation errors
																// when opening previously
																p.form.validateAllFields();
															}
														}}
														onApplyChanges={() => scope.update(() => {
															Object.assign(model, tmpObj);
														})}
														content={() => renderOptionsEdit(p)}
													/>
												)}
											/>
										</Grid>
									)}
								</>
							)}
							<Grid item>
								<Checkbox
									label="Multi select"
									textFieldAlign
									onFieldUpdate={() => model.resetDefault()}
									{...field('multiSelect')}
								/>
							</Grid>
							{hasOptions && isDbIdType(optionType) && (
								<Grid item>
									<Checkbox
										label="Include IDs not present in file"
										textFieldAlign
										{...field('includeNonPresent')}
									/>
								</Grid>
							)}
						</Grid>
					</Box>
				);
			}}
		/>
	);
};
const renderData = (p) => {
	if (isNonStandard(p)) {
		return renderNonStandardString({
			name: 'value',
			label: p.description,
			field: p.field,
			model: p.value,
			required: p.isRequired,
			inDisabled: p.inDisabled,
		});
	}
	return (
		<TextField
			{...(p.field('value'))}
			label={p.description}
			margin="none"
			required={p.isRequired}
			disabled={p.inDisabled}
			fullWidth
			hideLabelIfEmpty
			shrink
			inputLabelProps={{ style: { background: 'white' } }}
		/>
	);
};

const StringType = {
	style: { flex: 'auto' },
	canInnerDisable: true,
	inlineRender: true,
	fieldEditNoInline: true,
	defaultValue: '',
	render: renderEdit,
	renderData,
	renderSummary: (obj) => <span style={{ fontWeight: 'bold' }}>{stringDesc(obj)}</span>,
};

export default StringType;
