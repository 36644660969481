/* eslint-disable react/jsx-one-expression-per-line, react/no-unescaped-entities, max-len */
import React from 'react';

import DfpPriceFloorState from 'relevant-shared/misc/dfpPriceFloorState';

const { Constants } = DfpPriceFloorState;

const Snippets = {
	priceRulesBlocked: {
		notReady: (
			<>
				Please check errors in <b>Google UI credentials</b> in order to set up price rules.
				If you don't want these credentials saved in the database they can be omitted - as you can enter
				them temporarily later while performing price-rule operations.
			</>
		),
		isNew: (
			<>
				You have not saved this new adserver yet. In order to edit GAM price rules, please save first.
			</>
		),
		connectionChanged: (
			<>
				You have now changed Google UI credentials. To edit GAM price rules, please save first.
			</>
		),
	},
	keyValActionNeeded: (
		<>
			The Key-value <b>{Constants.KEY_NAME}</b> is not ready yet.
			This must be done before setting up price rules.
		</>
	),
	keyValCreateMessage: (
		<>
			This operation will create:
			<ul style={{ listStyle: 'initial' }}>
				<li>One Targeting key <b>{Constants.KEY_NAME}</b></li>
				<li>{Constants.NUM_KEY_VALUES} Targeting values "<b>1</b>".."<b>{Constants.NUM_KEY_VALUES}</b>"</li>
			</ul>
		</>
	),
	keyValReSyncMessage: (
		<>
			<span>
				Are you sure you want to re-sync the Key-values to GAM? This is normally not necessary <i>unless</i>
			</span>
			<ul style={{ listStyle: 'initial' }}>
				<li>The targeting key <b>{Constants.KEY_NAME}</b> has been deleted/deactivated</li>
				<li>Any of its' targeting values ("1".."{Constants.NUM_KEY_VALUES}") have been deleted/deactivated</li>
			</ul>
		</>
	),
	updateMessage: (p) => (
		<>
			This operation will create:
			<ul style={{ listStyle: 'initial' }}>
				<li>Rules to <i>create</i>: <b>{p.toCreate}</b></li>
				<li>Rules to <i>activate</i>: <b>{p.toActivate}</b></li>
				<li>Rules to <i>deactivate</i>: <b>{p.toDeactivate}</b></li>
				<li>Active rules with CPM adjustments: <b>{p.toUpdateCpm}</b></li>
			</ul>
		</>
	),
	googleLoginInfo: (
		<>
			In order to edit unified price rules in Google Ad Manager we need to perform a Google login using
			the normal Google credentials - as there is no support for editing price rules in the Google Ad Manager API.
			Therefore you need to enter the information below.
			<br /><br />
			This account information is used immediately by our
			servers and is not persisted or logged in any way.
		</>
	),
	passwordProbablyWrong: (
		<>
			If the password is pre-filled below, it is because the browser is filling in your Yield-password.
			If so, please remove it and enter your Google password instead.
		</>
	),
	phoneNumberDescription: (
		<>
			Google wants to send an SMS with a verification code. Please include the country code including <b>+</b>.
			Example: <b>+46708123456</b>
		</>
	),
};

export default Snippets;
