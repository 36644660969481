/* eslint-disable react/forbid-prop-types, react/jsx-one-expression-per-line, react/no-unused-prop-types, react/function-component-definition */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CLUSTER_INFO_URL } from 'relevant-shared/constants';
import Disabler from '../Disabler';
import SystemData from '../../lib/systemData';
import FieldArray from '../FieldArray';
import TextField from '../TextField';
import Credentials from '../Credentials';
import Checkbox from '../Checkbox';
import OperationWrapper from '../OperationWrapper';
import ExpandSelector from '../ExpandSelector';

const renderProcessList = ({ popup, cluster, field }) => (
	<FieldArray
		model={cluster}
		field={field}
		name="processes"
		update={() => popup.update()}
		createNew={() => new SystemData.models.Process()}
		render={(fld, elm) => (
			<div>
				<TextField required label="Host" {...fld('host')} fullWidth margin="normal" />
				<Credentials
					model={elm.credentials}
					field={(name) => fld(`credentials.${name}`)}
				/>
				{elm.processNr && (
					<span>Process Nr: {elm.processNr}</span>
				)}
			</div>
		)}
	/>
);

const renderClusterList = ({ clusterInfo, form, cluster }) => {
	const { selectedClusters } = cluster;
	const setIncluded = (id, include) => {
		const newList = selectedClusters?.filter((elm) => elm.id !== id) || [];
		if (include) {
			newList.push({ id });
		}
		form.update(() => {
			cluster.selectedClusters = newList;
		});
	};
	return (
		<Grid container style={{ paddingTop: 24 }}>
			{clusterInfo.clusters.map(({ id, name }) => (
				<Grid item xs={12} key={id} style={{ margin: '0px 0px 0px 30px', padding: 0 }}>
					<Checkbox
						formStyle={{ margin: 0 }}
						label={name}
						name={`include_${id}`}
						value={selectedClusters?.some((elm) => elm.id === id)}
						onChange={(ev) => setIncluded(id, ev.target.value)}
					/>
				</Grid>
			))}
		</Grid>
	);
};

const CardWithContainer = ({ title, children }) => (
	<Card>
		<CardContent>
			<Typography variant="h2">
				{title}
			</Typography>
			<Grid container spacing={3}>
				{children}
			</Grid>
		</CardContent>
	</Card>
);

CardWithContainer.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
};

const renderGeneralSettings = ({ field }) => (
	<CardWithContainer title="General settings">
		<Grid item xs={12}>
			<Checkbox
				label="Update process by cluster list"
				{...field('updateByClusterList')}
			/>
		</Grid>
	</CardWithContainer>
);

function ClusterEdit(props) {
	const { cluster } = props;
	const autoUpdate = cluster.updateByClusterList;
	const ProcListContainer = autoUpdate ? ExpandSelector : (p) => (<>{p.children}</>);
	const [clusterInfo, setClusterInfo] = useState(false);
	const [editAnyway, setEditAnyway] = useState(false);
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				{renderGeneralSettings(props)}
			</Grid>
			<Grid item xs={12}>
				<CardWithContainer title="Select clusters">
					<OperationWrapper
						fn={async () => {
							setClusterInfo(await (await window.fetch(CLUSTER_INFO_URL)).json());
						}}
						content={() => renderClusterList({ ...props, clusterInfo })}
					/>
				</CardWithContainer>
			</Grid>
			<Grid item xs={12}>
				<CardWithContainer title="Servers">
					<Grid item xs={12}>
						<ProcListContainer title="Current list (not updated by selection above)">
							{autoUpdate && (
								<Checkbox
									formStyle={{ margin: 0 }}
									label="Edit (only for updating credentials on existing servers)"
									name="edit_anyway"
									value={editAnyway}
									onChange={(ev) => setEditAnyway(ev.target.value)}
								/>
							)}
							<Disabler disabled={autoUpdate && !editAnyway}>
								{renderProcessList(props)}
							</Disabler>
						</ProcListContainer>
					</Grid>
				</CardWithContainer>
			</Grid>
		</Grid>
	);
}

ClusterEdit.propTypes = {
	popup: PropTypes.object.isRequired,
	cluster: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
};

ClusterEdit.defaultProps = {
};

export default ClusterEdit;
