exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".HideScrollbars__src-components-Carousel-Carousel__nIDxV {\n\tscrollbar-width: none;\n}\n.HideScrollbars__src-components-Carousel-Carousel__nIDxV::-webkit-scrollbar {\n\tdisplay: none;\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/worker/temp/relevant-yield/relevant-frontend/src/components/Carousel/Carousel.css"],"names":[],"mappings":"AAAA;CACC,sBAAqB;CACtB;AACA;CACC,cAAa;CACd","file":"Carousel.css","sourcesContent":[".HideScrollbars {\n\tscrollbar-width: none;\n}\n.HideScrollbars::-webkit-scrollbar {\n\tdisplay: none;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"HideScrollbars": "HideScrollbars__src-components-Carousel-Carousel__nIDxV"
};