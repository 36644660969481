import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validation from '../../lib/validation';
import filters from '../../lib/filters';
import FormContext from '../../components/FormContext';

let currentInfoDst;

const ISOLATED_PROPS = ['value', 'error'];
const isolatedValueMap = new WeakMap();

function FormWrap(WrappedComponent) {
	class Form extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				errors: {},
			};
			this.stateUpdateCount = 0; // To keep track if there are any pending state updates
			this.fieldRefs = {};
			this.field = this.field.bind(this);
			this.value = this.value.bind(this);
			this.submit = this.submit.bind(this);
			this.hasValue = this.hasValue.bind(this);
			this.onKeyPress = this.onKeyPress.bind(this);

			if (props.draft) this.state.data = _.merge({}, props.model);
		}

		componentDidMount() {
			const { onMount, formCollection } = this.props;
			if (onMount) onMount(this);
			document.addEventListener('keypress', this.onKeyPress);
			if (formCollection) {
				formCollection.add(this);
			}
		}

		componentDidUpdate(prevProps) {
			/* Workaround to be able to use the built-in form validation
				without having to call any form functions from outside this component,
				which is generally not recommended in react.
				The form can still be used 'the old way' as before, simply do not use the 'shouldSubmit' prop. */
			if (this.props.shouldSubmit && prevProps.shouldSubmit !== true) {
				const hasErrors = this.hasErrors();
				this.props.onSpecialSubmit(hasErrors);
			}
		}

		componentWillUnmount() {
			const { onUnmount, formCollection } = this.props;
			if (onUnmount) onUnmount(this);
			document.removeEventListener('keypress', this.onKeyPress);
			if (formCollection) {
				formCollection.remove(this);
			}
		}

		onKeyPress(e) {
			const { submitOnEnter } = this.props;
			const key = e.which || e.keyCode;
			if (key === 13 && document.activeElement.type !== 'textarea') {
				e.preventDefault();
				if (submitOnEnter || typeof submitOnEnter === 'undefined') this.submit();
			}
		}

		getStateObject() {
			return this.props.draft
				? _.merge({}, this.state.data)
				: this.props.model;
		}

		setStateValue(name, value) {
			const { setCustomizer } = this.props;
			const state = this.getStateObject();
			_.setWith(state, name, value, setCustomizer);

			if (this.props.draft) {
				this.updateState({ data: state });
			}
			return value;
		}

		setVals(newVals, waitUpdate) {
			_.forOwn(newVals, (val, key) => {
				const { props } = this.fieldRefs[key] || {};
				this.updateField(key, val, props);
			});
			return waitUpdate && new Promise((r) => { this.updateState({}, r); });
		}

		update(fn) {
			if (fn) {
				fn();
			}
			this.validateAllFields();
		}

		updateField(name, value, props) {
			const { onFieldUpdate } = this.props;
			const {
				reaction, preReaction, onFieldUpdate: fldUpdate, isolated,
			} = props || {};
			// Update the field value itself.
			const filteredValue = this.filterField(name, value, props);
			if (preReaction && _.isFunction(preReaction)) {
				preReaction(filteredValue);
			}
			this.setStateValue(name, filteredValue);

			let fieldErrors = [];
			// Now validate the field.
			if (props) {
				fieldErrors = this.validateField(name, filteredValue, props);
			}

			onFieldUpdate?.(name, value, fieldErrors);
			fldUpdate?.(name, value, fieldErrors);

			const postFn = (reaction && _.isFunction(reaction) && (() => {
				const updater = (n, v) => this.updateField(n, v, null);
				reaction(updater, value, fieldErrors.length > 0);
			})) || undefined;

			const updateStateErrors = (state) => {
				const errors = _.merge({}, state.errors);
				if (fieldErrors.length) {
					errors[name] = fieldErrors;
				} else {
					delete errors[name];
				}
				return { errors };
			};
			const ref = this.fieldRefs[name];
			if (ref && isolated && !postFn && !this.stateUpdateCount) {
				// performance quick-fix for certain heavily used text fields on publisher page, etc
				updateStateErrors(this.state);
				isolatedValueMap.set(ref, {
					newProps: { value, error: fieldErrors[0] },
					oldProps: _.pick(ref.props, ISOLATED_PROPS),
				});
				ref.forceUpdate();
			} else {
				if (ref && !postFn && !this.stateUpdateCount && FormContext.contexts.length) {
					const elementName = ref.props.name;
					const elms = document.getElementsByName(elementName);
					if (elms.length === 1) {
						const formContextParent = elms[0].closest('[data-form-context-id]');
						const id = formContextParent?.getAttribute('data-form-context-id');
						const formCtx = FormContext.contexts.find((ctx) => ctx.id === id);
						if (formCtx) {
							formCtx.update();
							Object.assign(this.state, updateStateErrors(this.state));
							return;
						}
					}
				}
				this.updateState(updateStateErrors, postFn);
			}
		}

		filterField(name, value, props) {
			const { disableAutoFilters } = this.props;

			const filterKeys = disableAutoFilters ? [] : _.intersection(this.getPropKeys(props), _.keys(filters));
			const customFilter = (props && props.filter && _.isFunction(props.filter)) ? props.filter : null;

			const resolvedFilters = filterKeys.map((key) => filters[key](props[key]));
			if (customFilter) resolvedFilters.push(customFilter);

			return resolvedFilters.reduce((transformedValue, currentFilter) => currentFilter(transformedValue), value);
		}

		validateField(name, value, props) {
			if (props.disableValidation) {
				return [];
			}
			let validators = _.intersection(this.getPropKeys(props), _.keys(validation))
				.map((key) => validation[key](props[key]));

			if (validators && validators.length && validators.length > 0) {
				if (value == null || value === '') {
					validators = _.filter(validators, 'checkOnEmpty');
				}
				return validators
					.filter((validator) => !validator.valid(value))
					.map((validator) => validator.message(value));
			}

			return [];
		}

		validateAllFields() {
			const refs = this.fieldRefs;
			const errors = {};
			let minErrorY;
			_.each(refs, (ref) => {
				if (!ref || !ref.props) return;

				if (ref.props.name) {
					const validatorProps = ref.getValidatorProps
						? ({ ...ref.getValidatorProps(), ...ref.props })
						: ref.props;

					const fieldErrors = this.validateField(
						ref.props.name,
						_.get(this.getStateObject(), ref.props.name),
						validatorProps,
					);

					if (fieldErrors.length > 0) {
						errors[ref.props.name] = fieldErrors;
						const nodes = document.getElementsByName(ref.props.name);
						// TODO: Not 100% guaranteed to find the correct DOM node, but good enough for now.
						// A permanent solution would expose a DOM ref/id/href to ensure deterministic lookups.
						const { value } = ref.props;
						const elm = Array.prototype.find.call(nodes, (el) => (
							_.isEqual(el.value, value) || (el.value === '' && (value === undefined || value === null))
						));
						if (elm) {
							const { y } = elm.getBoundingClientRect();
							minErrorY = Math.min(minErrorY === undefined ? y : minErrorY, y);
						}
					}
				}
			});
			if (minErrorY !== undefined && minErrorY < 0) { // scroll back to first field with an error
				scrollBy(0, minErrorY - 80); // scroll a bit extra to make sure visible
			}

			this.updateState({ errors });

			return errors;
		}

		getPropKeys(props) {
			return _.keys(props)
				// Avoid getting false-positives from various input components' defaultProps
				.filter((key) => props[key] !== false);
		}

		updateState(newState, cb) {
			this.stateUpdateCount += 1;
			this.setState((...params) => {
				this.stateUpdateCount -= 1;
				return _.isFunction(newState) ? newState(...params) : newState;
			}, cb);
		}

		hasErrors(dontValidateNow) {
			// eslint-disable-next-line react/destructuring-assignment
			const errors = _.keys(dontValidateNow ? this.state.errors : this.validateAllFields());
			return errors.length ? errors : null;
		}

		resetErrors() {
			this.updateState({ errors: {} });
		}

		async submit(extra, { checkFormCollection } = {}) {
			const { onSubmit, formCollection, submitCallback } = this.props;
			const hasErrors = checkFormCollection && formCollection ? formCollection.checkErrors() : this.hasErrors();

			if (!hasErrors && onSubmit) {
				await onSubmit(this.getStateObject(), extra, { form: this });
				if (submitCallback) {
					submitCallback();
				}
			}
			return !hasErrors;
		}

		rootFields() {
			const seen = {};
			Object.keys(this.fieldRefs).forEach((key) => {
				const match = (/(.*?)[.|[]/.exec(key) || [])[1]; // until '.' OR '['
				seen[match || key] = true;
			});
			return Object.keys(seen);
		}

		field(name) {
			const that = this;
			class PropObj {
				constructor(p) { Object.assign(this, p); }

				// Use field('bla').validation({ ... }) to do the validation immediately using the provided
				// validators. In this case *all* validators must be specified that way instead of using
				// them as props the normal way. */
				// eslint-disable-next-line react/no-unused-class-component-methods
				validation(props = {}) {
					Object.assign(this, props);
					const [err] = that.validateField(
						name,
						_.get(that.getStateObject(), name),
						props,
					);
					if (err && !this.disableValidation) {
						this.error = err;
					} else {
						delete this.error;
					}
					return this;
				}
			}
			const properties = new PropObj({
				name,
				value: _.get(this.getStateObject(), name),
				onChange: (event, props) => this.updateField(event.target.name, event.target.value, props),
				ref: (element) => {
					this.fieldRefs[name] = element;
				},
			});
			if (FormWrap.Context.disableValidation) {
				properties.disableValidation = true;
			} else if (name in this.state.errors) {
				properties.error = this.state.errors[name];
				if (_.isArray(properties.error)) {
					properties.error = properties.error[0];
				}
			}
			if (currentInfoDst) {
				currentInfoDst = { form: this };
			}
			return properties;
		}

		value(name) {
			return _.get(this.getStateObject(), name);
		}

		hasValue(name) {
			return Boolean(this.value(name));
		}

		collectionAdd(path, template) {
			if (_.has(this.getStateObject(), path)) {
				const index = _.get(this.getStateObject(), path).length;
				const target = `${path}[${index}]`;
				this.setStateValue(target, template);
				if (!this.props.draft) {
					this.forceUpdate();
				}
			}
		}

		collectionRemove(path, index) {
			const state = this.getStateObject();
			if (_.has(state, path)) {
				_.get(state, path).splice(index, 1);

				if (this.props.draft) {
					this.updateState({ data: state });
				} else {
					this.forceUpdate();
				}
			}
		}

		formCollection() {
			return this.props.formCollection;
		}

		render() {
			const formMethods = {
				field: this.field,
				value: this.value,
				submit: this.submit,
				hasValue: this.hasValue,
				model: this.getStateObject(),
				collectionAdd: (path, template) => this.collectionAdd(path, template),
				collectionRemove: (path, index) => this.collectionRemove(path, index),
				validateFields: () => this.validateAllFields(),
			};
			return <WrappedComponent {...this.props} {...formMethods} form={this} />;
		}
	}

	Form.propTypes = {
		model: PropTypes.object.isRequired,
		onSubmit: PropTypes.func,
		// See componentDidUpdate function for details on shouldSubmit and onSpecialSubmit
		shouldSubmit: PropTypes.bool,
		onSpecialSubmit: PropTypes.func,
		draft: PropTypes.bool,
		onUnmount: PropTypes.func,
		onMount: PropTypes.func,
		disableAutoFilters: PropTypes.bool,
		submitOnEnter: PropTypes.bool,
		setCustomizer: PropTypes.func,
		onFieldUpdate: PropTypes.func,
		formCollection: PropTypes.object,
	};

	Form.defaultProps = {
		draft: false,
		onSubmit: () => {},
		shouldSubmit: undefined,
		onSpecialSubmit: undefined,
		onUnmount: undefined,
		onMount: undefined,
		disableAutoFilters: false,
		submitOnEnter: true,
		setCustomizer: undefined,
		onFieldUpdate: undefined,
		formCollection: undefined,
	};

	Form.wrappedComponent = WrappedComponent;

	return Form;
}

FormWrap.Context = {
	disableValidation: false,
};

FormWrap.fromField = (field) => {
	currentInfoDst = {};
	field();
	const { form } = currentInfoDst;
	currentInfoDst = null;
	return form;
};

FormWrap.metaFields = [...Object.keys(validation), 'disableValidation'];

// To be called by components supporting the "isolated" property to avoid form re-renders
const getFormPropsFor = (component) => {
	const info = isolatedValueMap.get(component);
	if (!info) {
		return component.props;
	}
	const { newProps, oldProps } = info;
	if (ISOLATED_PROPS.find((p) => component.props[p] !== oldProps[p])) {
		// Component got new properties, we're done for this time
		isolatedValueMap.delete(component);
		return component.props;
	}
	return newProps;
};

export default FormWrap;
export { getFormPropsFor };
