import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Box, Grid } from '@mui/material';
import MiscUtils from '../../lib/miscUtils';
import PopupSelector from '../PopupSelector';
import FieldArray from '../FieldArray';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import SystemData from '../../lib/systemData';
import BidParamEdit from '../BidParamEdit';
import { Ssp, Adserver } from '../../api/relevant';
import ExpandSelector from '../ExpandSelector';
import Select from '../Select';

class PlacementTypeEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = { systems: props.systems };
	}

	render() {
		const {
			model,
			form,
			field,
			nonDeletable,
			loadFn,
			canDelete,
			systems,
			globalSettings,
			onDone,
			onCancel,
			customLink,
		} = this.props;

		return (
			<PopupSelector
				fn={async () => {
					if (!systems) {
						const [__, ssps, adservers] = await Promise.all([
							loadFn ? loadFn() : null,
							Ssp.call('getAll'),
							Adserver.call('getAll'),
						]);
						this.setState({ systems: { ssps, adservers } });
					} else if (loadFn) {
						await loadFn();
					}
				}}
				form={form}
				title="Placement Types"
				size="md"
				onApplyChanges={onDone}
				onCancel={onCancel}
				customLink={customLink}
				okLabel="Save"
			>
				<FieldArray
					update={() => form.update()}
					model={model}
					expandableElements
					expandStyle={{ padding: '0px 10px' }}
					confirmDelete="Do you want to delete this placement type?"
					canDelete={(elm) => !_.includes(nonDeletable, elm.id) && canDelete(elm)}
					afterHeader={(__, elm) => `${elm.name || 'No name'} (${elm.dimensions.length ? elm.dimensions.map((d) => `${d.width}x${d.height}`).join(', ') : 'No dimensions'})`}
					noSwapBtns
					render={(fld, placType) => (
						<Grid container spacing={3}>
							<Grid item xs={3}>
								<TextField required label="Name" {...fld('name')} />
							</Grid>
							<Grid item xs={3}>
								<Select
									name="mediaType"
									label="Is Instream placement"
									required
									fullWidth
									{...fld('mediaType')}
									items={[
										{
											label: 'No',
											value: 'banner',
										},
										{
											label: 'Yes',
											value: 'instream',
										},
									]}
								/>
							</Grid>
							<Grid item xs={3}>
								<BidParamEdit
									form={form}
									dstObj={placType}
									onDone={() => form.update()}
									ssps={this.state.systems.ssps}
									adservers={this.state.systems.adservers}
									checkbox={!!placType.bidParams.find((b) => !_.isEmpty(b.params))}
									globalSettings={globalSettings}
								/>
							</Grid>
							<Grid item xs={3}>
								<PopupSelector form={form} title="Dimensions" size="md" canCancel={false}>
									<FieldArray
										update={() => form.update()}
										model={placType}
										render={(dimFld, dimElm) => (
											<>
												<Grid container spacing={3}>
													<Grid item xs={4}>
														<TextField
															integer
															between={{ low: 0, high: 10000 }}
															required
															label="Width"
															fullWidth
															{...dimFld('width')}
														/>
													</Grid>
													<Grid item xs={4}>
														<TextField
															integer
															between={{ low: 0, high: 10000 }}
															required
															label="Height"
															fullWidth
															{...dimFld('height')}
														/>
													</Grid>
													<Grid item xs={4}>
														<TextField
															label="Custom string"
															fullWidth
															{...dimFld('customStr')}
														/>
													</Grid>
												</Grid>
												<ExpandSelector
													title="Limit to viewport dimensions"
													expanded={_.some(_.pick(dimElm, [
														'minWidth', 'maxWidth', 'minHeight', 'maxHeight',
													]))}
												>
													<Grid container spacing={3}>
														<Grid item xs={6}>
															<TextField
																integer
																between={{ low: 0, high: 10000 }}
																label="Min. viewport width (inclusive)"
																shrink
																fullWidth
																{...dimFld('minWidth')}
															/>
														</Grid>
														<Grid item xs={6}>
															<TextField
																integer
																between={{ low: 0, high: 10000 }}
																label="Max viewport width (exclusive)"
																shrink
																fullWidth
																{...dimFld('maxWidth')}
															/>
														</Grid>
														<Grid item xs={6}>
															<TextField
																integer
																between={{ low: 0, high: 10000 }}
																label="Min. viewport height (inclusive)"
																shrink
																fullWidth
																{...dimFld('minHeight')}
															/>
														</Grid>
														<Grid item xs={6}>
															<TextField
																integer
																between={{ low: 0, high: 10000 }}
																label="Max viewport height (exclusive)"
																shrink
																fullWidth
																{...dimFld('maxHeight')}
															/>
														</Grid>
													</Grid>
												</ExpandSelector>
											</>
										)}
										name="dimensions"
										field={fld}
										createNew={() => ({ width: 0, height: 0, _id: MiscUtils.objectIdStr() })}
									/>
								</PopupSelector>
								<br />
								{!placType.dimensions.length ? <span style={{ color: 'red' }}>No dimensions</span> : (
									<span>{placType.dimensions.map((d) => `${d.width}x${d.height}`).join(', ')}</span>
								)}
							</Grid>
							<Grid item>
								<Checkbox label="GAM fluid dimension" {...fld('makeFluid')} />
							</Grid>
						</Grid>
					)}
					name="placementTypes"
					field={field}
					createNew={() => new SystemData.models.PlacementType({
						name: 'New placement type',
						type: 'banner',
						dimensions: [{ width: 0, height: 0 }],
					})}
				/>
			</PopupSelector>
		);
	}
}

PlacementTypeEdit.propTypes = {
	model: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	nonDeletable: PropTypes.array,
	canDelete: PropTypes.func,
	loadFn: PropTypes.func,
	systems: PropTypes.object,
	onDone: PropTypes.func,
	onCancel: PropTypes.func,
	customLink: PropTypes.func,
	globalSettings: PropTypes.object.isRequired,
};

PlacementTypeEdit.defaultProps = {
	nonDeletable: [],
	loadFn: () => {},
	canDelete: () => true,
	systems: undefined,
	onDone: undefined,
	onCancel: undefined,
	customLink: undefined,
};

export default PlacementTypeEdit;
