import React, { useEffect } from 'react';
import { useToast } from '../../hooks/useToast';
import Base from '../../layouts/Base';

const triggerToast = (props) => {
	function ToastWrap({ closeFn, onClose, ...rest }) {
		const [, showToast] = useToast();
		useEffect(() => showToast({
			onClose: (...args) => {
				onClose?.(...args);
				closeFn();
			},
			...rest,
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}), []);
	}
	Base.renderGlobal((closeFn) => <ToastWrap closeFn={closeFn} {...props} />);
};

export {
	triggerToast,
};
