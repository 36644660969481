/* eslint-disable react/function-component-definition, react/no-unstable-nested-components, react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import DateUtils from 'relevant-shared/misc/dateUtils';
import ExternalDataImport from './index';
import ExternalSystemEdit from '../ExternalSystemEdit';
import ExpandSelector from '../ExpandSelector';
import { SimpleContainer, Scope } from '../Wrappers';
import ImportStatsTable from '../ImportStatsTable';
import Checkbox from '../Checkbox';

const RtImporterCustomImport = ({ system }) => !system.isNew && (
	<Scope
		ignoreFloors={false}
		content={({ fld }, settings) => (
			<>
				<ExpandSelector title="Import real time data">
					<ExternalDataImport
						model={system}
						importFn={(__, from, to, fromHour, toHour) => system.importRtData({
							...settings, from, to, fromHour, toHour,
						})}
						maxDate={DateUtils.today()}
						hasHourSetting
						beforeImportBtn={() => (
							<Grid item>
								<Checkbox
									label="Don't import by price floor"
									{...fld('ignoreFloors')}
								/>
							</Grid>
						)}
						dataCols={[
							{ key: 'totalRevenue', title: 'Change of revenue' },
							{ key: 'totalImpressions', title: 'Impressions updated' },
						]}
					/>
				</ExpandSelector>
				<SimpleContainer
					component={ImportStatsTable}
					init={async () => ({ data: await system.storedStats() })}
				/>
			</>
		)}
	/>
);

RtImporterCustomImport.propTypes = {
	system: PropTypes.object.isRequired,
};

ExternalSystemEdit.registerCustomRenderer('RtImporterCustomImport', RtImporterCustomImport);
