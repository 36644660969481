import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { asyncEvent } from 'relevant-shared/misc/misc';
import MiscUtils from '../../lib/miscUtils';
import { withUpdate } from '../Wrappers';
import styles from './styles.css';
import Disabler from '../Disabler';
import { Dialog } from '../Dialog';

@withUpdate
class OperationWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.nonWorkingWaiters = asyncEvent();
	}

	componentDidMount() {
		this.startIfNeeded();
	}

	getDimensions()	{
		return this.container ? ({
			width: `${this.container.clientWidth}px`,
			height: `${this.container.clientHeight}px`,
		}) : null;
	}

	updateState(newState, ...rest) {
		Object.assign(this, newState);
		if (!this.isWorking) {
			this.nonWorkingWaiters.trigger();
		}
		this.setState(newState, ...rest);
	}

	shouldChangeTarget() {
		return this.props.reloadable && !_.isEqual(this.prevLoad, this.props.load);
	}

	shouldStartLoad() {
		return (this.props.load && !this.loadingStarted) || this.checkReloadIf();
	}

	checkReloadIf() {
		return !this.isWorking && this.props.reloadable && this.props.reloadIf();
	}

	needsStart() {
		return !this.errorMsg && (this.shouldStartLoad() || this.shouldChangeTarget());
	}

	async reload(reloadFn, tryKeepPosition = true) {
		while (this.isWorking) {
			await this.nonWorkingWaiters.wait();
		}
		if (this.errMsg) {
			throw Error('OperationWrapper.reload() not supported after errors');
		}
		return this.startOperation(reloadFn, tryKeepPosition);
	}

	startIfNeeded(usingYPos) {
		const { load } = this.props;
		const { loadingStarted } = this;
		const dimensions = this.getDimensions();
		if (this.shouldChangeTarget()) {
			this.updateState({
				isWorking: false,
				errorMsg: null,
				showModal: false,
				loadingStarted: false,
				prevLoad: load,
				gotoY: loadingStarted ? (usingYPos || pageYOffset) : undefined,
			});
		}
		if (this.shouldStartLoad()) {
			this.startOperation(undefined, loadingStarted, usingYPos, dimensions);
		}
	}

	runFn() {
		return this.props.fn(this);
	}

	async startOperation(fn, tryKeepPosition, usingYPos, orgDimensions = this.getDimensions()) {
		let errorMsg;
		const yOfs = usingYPos || this.gotoY || pageYOffset;
		let { hasSucceeded } = this;

		/** if we're reloading */
		const divStyle = tryKeepPosition && hasSucceeded ? orgDimensions : null;

		this.updateState({ isWorking: true, loadingStarted: true, divStyle });
		const opFn = async () => {
			await (fn || this.props.fn)(this);
			hasSucceeded = true;
		};
		const finishFn = () => new Promise((resolve) => this.updateState({
			gotoY: undefined, isWorking: false, hasSucceeded, divStyle: null,
		}, () => {
			if (tryKeepPosition && yOfs && !divStyle) {
				setTimeout(() => scrollBy(0, yOfs - pageYOffset));
			}
			resolve();
		}));

		if (window.location.toString().indexOf('op-no-catch') >= 0) { // will simplify debugging sometimes
			await opFn();
			await finishFn();
		} else {
			try {
				await opFn(this);
			} catch (e) {
				errorMsg = MiscUtils.errorMsg(e);
				this.updateState({ errorMsg, showModal: true });
			} finally {
				await finishFn();
			}
		}
		return errorMsg;
	}

	render() {
		if (this.needsStart()) {
			const yPos = window.pageYOffset;
			setTimeout(() => this.startIfNeeded(yPos));
		}
		const {
			content, style, onAfterErrorMsg, disableMode,
		} = this.props;
		const {
			isWorking, errorMsg, showModal, loadingStarted, hasSucceeded, divStyle,
		} = this;

		const renderContentDiv = () => {
			const children = content ? ((!isWorking || disableMode) && !errorMsg && content(this)) : this.props.children;
			const renderNormal = !isWorking && !errorMsg;
			if (disableMode) {
				return (
					<Disabler disabled={!renderNormal}>
						{children}
					</Disabler>
				);
			}
			return (
				<div style={{ display: renderNormal ? 'block' : 'none' }}>
					{children}
				</div>
			);
		};

		return !loadingStarted ? <div /> : (
			<div ref={(elm) => { this.container = elm; }} className={styles.container} style={({ ...divStyle, ...style })}>
				{((isWorking || showModal) && !disableMode) && (
					<div className={styles.progressContainer}>
						<CircularProgress size={100} />
					</div>
				)}
				{errorMsg && !showModal && <Box component="span" color="error.main">{errorMsg}</Box>}
				{renderContentDiv()}
				<Dialog
					open={!!showModal}
					text={errorMsg}
					status="error"
					onClose={() => {
						this.updateState({
							showModal: false,
							errorMsg: hasSucceeded ? undefined : errorMsg, // reset error if we've succeded one but failed a reload
						});
						if (onAfterErrorMsg) {
							onAfterErrorMsg(errorMsg);
						}
					}}
				/>
			</div>
		);
	}
}

OperationWrapper.propTypes = {
	fn: PropTypes.func,
	load: PropTypes.any,
	reloadable: PropTypes.bool,
	reloadIf: PropTypes.func,
	content: PropTypes.func,
	style: PropTypes.object,
	onAfterErrorMsg: PropTypes.func,
	disableMode: PropTypes.bool,
};

OperationWrapper.defaultProps = {
	fn: () => {},
	load: true,
	reloadable: false,
	reloadIf: () => {},
	content: undefined,
	style: undefined,
	onAfterErrorMsg: undefined,
	disableMode: false,
};

export default OperationWrapper;
