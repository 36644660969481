import React, { useState, useRef } from 'react';
import _ from 'lodash';
import {
	Grid, Card, CardContent, CardHeader,
} from '@mui/material';
import PlacementTypeEdit from '../PlacementTypeEdit';
import BidParamEdit from '../BidParamEdit';
import classes from '../../api/classes';
import { FormOf } from '../Wrappers';
import { ActionButton } from '../ActionButton/ActionButton';
import OperationWrapper from '../OperationWrapper';
import FormCollection from '../../lib/formCollection';

const {
	Publisher, GlobalSettingsObject, BaseSsp, BaseAdserver, BaseUserIdModule,
} = classes;

function HbManagement() {
	const [ssps, setSsps] = useState();
	const [adservers, setAdservers] = useState();
	const [userIdModules, setUserIdModules] = useState();
	const [usedPlacementTypes, setUsedPlacementTypes] = useState();
	const [globalSettings, setGlobalSettings] = useState();
	const operationWrapper = useRef();

	const fetchData = async () => {
		const [
			sspRes,
			userIdModulesRes,
			adserverRes,
			placementTypeRes,
			globalSettingsRes] = await Promise.all([
			BaseSsp.list(),
			BaseUserIdModule.list(),
			BaseAdserver.list(),
			Publisher.getPlacementTypeUsage(),
			GlobalSettingsObject.listOne(),
		]);

		setSsps(sspRes);
		setAdservers(adserverRes);
		setUserIdModules(userIdModulesRes);
		setUsedPlacementTypes(placementTypeRes);
		setGlobalSettings(globalSettingsRes);
	};

	const onEditDone = () => {
		operationWrapper.current.reload(async () => {
			await globalSettings.update(null, { updateUsingDiff: true });
		});
	};

	function handleCancel() {
		operationWrapper.current.reload();
	}

	return (
		<Grid
			container
			justifyContent="center"
		>
			<Grid item>
				<Card>
					<CardHeader title="Global HB management" />
					<CardContent>
						<OperationWrapper
							fn={fetchData}
							// Setting as ref here directly instead of setting it
							// in the fn callback which seems to be what we usually do,
							// but this is simpler imo
							ref={operationWrapper}
							content={() => (
								<FormOf
									model={globalSettings}
									formCollection={new FormCollection()}
									content={({ field, form }) => (
										<Grid container spacing={3} justifyContent="center">
											<Grid item>
												<PlacementTypeEdit
													model={globalSettings}
													canDelete={({ id }) => !usedPlacementTypes[id]}
													form={form}
													field={field}
													systems={{ adservers, ssps, userIdModules }}
													globalSettings={globalSettings}
													onDone={onEditDone}
													onCancel={handleCancel}
													customLink={(showFn) => (
														<ActionButton
															label="Placement types"
															color="primary"
															onClick={() => {
																showFn();
															}}
														/>
													)}
												/>
											</Grid>
											<Grid item>
												<BidParamEdit
													form={form}
													dstObj={globalSettings}
													onDone={onEditDone}
													onCancel={handleCancel}
													ssps={ssps}
													adservers={adservers}
													userIdModules={userIdModules}
													globalSettings={globalSettings}
													okLabel="Save"
													customLink={(showFn) => (
														<ActionButton
															label="Prebid parameters"
															color="primary"
															onClick={() => {
																showFn();
															}}
														/>
													)}
												/>

											</Grid>
										</Grid>
									)}
								/>
							)}
						/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

export default HbManagement;
