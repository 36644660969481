exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".input__src-components-DateOrDaysPicker-styles__2BZ2u {\n    width: 7rem;\n}\n", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/worker/temp/relevant-yield/relevant-frontend/src/components/DateOrDaysPicker/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAW;CACf","file":"styles.css","sourcesContent":[".input {\n    width: 7rem;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"input": "input__src-components-DateOrDaysPicker-styles__2BZ2u"
};