exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".pageContentWrapper__src-pages-AdminDashboard-styles__1whb5 {\n\tpadding-bottom: 100px;\n}\n\n.dashboardSelectorContainer__src-pages-AdminDashboard-styles__1Hj_U {\n\tmax-width: 450px;\n    margin: 0 auto;\n}\n", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/worker/temp/relevant-yield/relevant-frontend/src/pages/AdminDashboard/styles.css"],"names":[],"mappings":"AAAA;CACC,sBAAqB;CACtB;;AAEA;CACC,iBAAgB;IACb,eAAc;CAClB","file":"styles.css","sourcesContent":[".pageContentWrapper {\n\tpadding-bottom: 100px;\n}\n\n.dashboardSelectorContainer {\n\tmax-width: 450px;\n    margin: 0 auto;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"pageContentWrapper": "pageContentWrapper__src-pages-AdminDashboard-styles__1whb5",
	"dashboardSelectorContainer": "dashboardSelectorContainer__src-pages-AdminDashboard-styles__1Hj_U"
};