import React, { ImgHTMLAttributes, useState, useEffect } from 'react';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
	fallback: string,
}
export default function ImageWithFallback({ fallback, src, alt, ...props }: Props) {
	const [hasError, setHasError] = useState(false);
	useEffect(() => setHasError(false), [src]);
	return (
		<img
			{...props}
			src={hasError ? fallback : src || fallback}
			alt={alt}
			onError={() => setHasError(true)}
		/>
	);
}
