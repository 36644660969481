exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".statusContainer__src-components-AppInstanceStatus-styles__NA46e {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    margin-right: 16px;\n}\n\n.statusIcon__src-components-AppInstanceStatus-styles__1vkZt {\n    margin-right: 8px;\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/worker/temp/relevant-yield/relevant-frontend/src/components/AppInstanceStatus/styles.css"],"names":[],"mappings":"AAAA;IACI,cAAa;IACb,oBAAmB;IACnB,0BAAyB;IACzB,mBAAkB;CACtB;;AAEA;IACI,kBAAiB;CACrB","file":"styles.css","sourcesContent":[".statusContainer {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    margin-right: 16px;\n}\n\n.statusIcon {\n    margin-right: 8px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"statusContainer": "statusContainer__src-components-AppInstanceStatus-styles__NA46e",
	"statusIcon": "statusIcon__src-components-AppInstanceStatus-styles__1vkZt"
};