const _ = require('lodash');
const PrebidConfigOwnerNode = require('./prebidConfigOwnerNode');
const PlacementNode = require('./placementNode');
const { s2sBidderSuffix, RLV_TARGET_PB_CFG_ID_KEY, RLV_TARGET_PB_CFG_NAME_KEY } = require('../../prebid/constants');
const { generateUserIdModuleConfig, splitParams } = require('../../prebid/bidParamUtils');
const {
	getVideoSettings, VIDEO_PARAMS_INTERNAL_FIELDS, isInstreamVideo,
} = require('../../prebid/video');
const { finalizeNativeSettings } = require('../../prebid/nativeServerOnly');

const selectAdserverOrders = (adUnits) => {
	const {
		true: instreamUnits = [],
		false: bannerUnits = [],
	} = _.groupBy(adUnits, (adUnit) => !!adUnit.isInstream);
	const byAdserver = (units) => _.map(_.sortBy(
		_.entries(_.groupBy(units, 'adserverId')),
		([, arr]) => -arr.length,
	), ([id]) => id); // sort adserver-ids by how many ad units they have (descending)
	const bannerAdsIds = byAdserver(bannerUnits);
	const instreamAdsIds = byAdserver(instreamUnits);
	return {
		allAdsIds: _.uniq([...bannerAdsIds, ...instreamAdsIds]),
		bannerAdsIds,
		instreamAdsIds,
	};
};

class SiteNode extends PrebidConfigOwnerNode {
	constructor(site, parentNode) {
		super(site, parentNode, 'SiteNode');
		site.placements.forEach((placement) => {
			this.children.push(new PlacementNode(placement, this));
		});
	}

	get name() {
		return this.obj.domain;
	}

	get userIdModules() {
		return this.publisherNode.userIdModules;
	}

	get allPbConfigs() { return Object.values(this.pbConfigsById); }

	generateUserIdModulesConfigs(pbConfigId) {
		const enabledUserIdModules = [];
		this.userIdModules.forEach((m) => {
			const prebidParams = this.bidderParams({ sspId: m.id, pbjsConfigId: pbConfigId });
			const moduleConfig = generateUserIdModuleConfig(prebidParams, m);
			if (moduleConfig) {
				enabledUserIdModules.push(moduleConfig);
			}
		});
		return enabledUserIdModules;
	}

	generatePrebidConfigs(tagCtx) {
		const { exchangeRates } = tagCtx;
		const { publisherNode } = this;
		const aliases = {};
		const s2sAliases = {};
		const configs = {};
		const byBidderInfo = {};
		const ctx = {};
		const usedAdserverIds = {};
		const enabledConfigurations = this.byType.PrebidConfigNode.filter(
			(pbConfigNode) => pbConfigNode.isEnabled,
		);
		enabledConfigurations.forEach((pbConfigNode) => {
			const { obj: pbConfig } = pbConfigNode;
			// Generate prebid userIds list
			const enabledUserIdModules = this.generateUserIdModulesConfigs(pbConfig.id);

			const allBidNodes = publisherNode.bidderNodesByConfig(pbConfig);
			const byPlac = _.groupBy(allBidNodes, (node) => node.placementNode.id);
			const placsPerSite = {};
			const placementTypesById = {};
			const adUnits = [];
			_.forOwn(byPlac, (bidNodes, placementId) => {
				const placement = publisherNode.byId[placementId];
				const { adserver, adserverSettings, placementType } = placement;
				if (!adserver || !adserverSettings || !placementType) {
					return;
				}
				const adserverUnitParams = adserver.getPrebidAdUnitParams({ placement, adserverSettings, ctx });
				if (!adserverUnitParams) { // Something wrong with adserver placement settings and we can't use it
					return;
				}
				const videoSettings = getVideoSettings(placement, placementType, pbConfig.id);
				const rawVideoSettings = videoSettings && _.omit(videoSettings, VIDEO_PARAMS_INTERNAL_FIELDS);
				const hasVideo = Boolean(videoSettings);
				const isInstream = isInstreamVideo(placementType);
				const { nativeSettings, internal: nativeInternal } = (isInstream ? undefined : finalizeNativeSettings(
					placement.bidderParams({ sspId: 'nativeSettings', pbjsConfigId: pbConfig.id }),
				)) || {};

				const formats = {
					banner: !isInstream
						&& nativeInternal?.nativeMode !== 'exclusive'
						&& videoSettings?.mediaType !== 'outstream',
					video: hasVideo,
					native: !!nativeSettings,
				};

				placementTypesById[placementType.id] = placementType;
				const siteId = placement.parentNode.id;
				placsPerSite[siteId] = (placsPerSite[siteId] || 0) + 1;
				const bids = [];
				bidNodes.forEach((bidNode) => {
					const { ssp, mediaTypeLimiter } = bidNode;
					const { bidderName } = ssp;
					const finalBidderName = ssp.allBidderNames()[0]; // TODO: doesn't work in frontend
					byBidderInfo[finalBidderName] = byBidderInfo[finalBidderName] || ssp.getGenericBidInfo();
					const allGenericParams = bidNode.bidderParams({ sspId: ssp.id, pbjsConfigId: pbConfig.id });
					const { normal: genericParams, internal: internalParams } = splitParams(allGenericParams);
					let placementParams = ssp.getPlacementBidParams(bidNode, {
						placementType,
						bids,
						genericParams,
						bidder: finalBidderName,
						videoSettings,
					}, tagCtx);
					// ignore mediaTypeLimiter for instream
					// (notice that placementType.mediaType === 'banner' for outstream/native)
					let finalMtl = mediaTypeLimiter === 'mixed' || placementType.mediaType !== 'banner'
						? null : mediaTypeLimiter;
					if (formats.banner && formats.video && internalParams.mixedFormatDisableVideo) {
						if (finalMtl === 'video') {
							return;
						}
						if (!finalMtl) {
							finalMtl = 'banner';
						}
					}
					if (finalMtl && !formats[finalMtl]) {
						return;
					}
					if (!placementParams || internalParams.disableBidder) {
						return;
					}
					if (!_.isArray(placementParams)) {
						placementParams = [placementParams];
					}
					if (finalMtl) {
						placementParams.forEach((obj) => {
							obj.__mtl = finalMtl;
						});
					}

					if (hasVideo) {
						const videoParams = {
							...ssp.getPlacementVideoBidParams(bidNode, {
								placementType,
								bids,
								genericParams,
								bidder: finalBidderName,
								videoSettings,
							}),
							...genericParams.video,
						};
						placementParams.forEach((obj) => {
							obj.video = videoParams;
						});
					}

					const clientBidsForNode = placementParams.map((params) => ({
						bidder: finalBidderName,
						__rlvId: bidNode.sspPlacNode.obj._id,
						params: {
							...genericParams,
							...params,
						},
					}));
					if (bidderName !== finalBidderName) {
						aliases[finalBidderName] = bidderName;
					}
					let serverBidsForNode = [];
					let { biddingType } = internalParams;
					if ((biddingType === 'server' || biddingType === 'parallel') && !ssp.hasPrebidServerSupport) {
						biddingType = 'client';
					}
					if (biddingType !== 'client') {
						const s2sName = s2sBidderSuffix(finalBidderName); // just add something
						s2sAliases[s2sName] = bidderName;
						serverBidsForNode = clientBidsForNode.map((obj) => (
							{
								...obj,
								bidder: s2sName,
							}
						));
					}
					let allBidsForNode = [];

					if (biddingType === 'client') {
						allBidsForNode = [...clientBidsForNode];
					} else if (biddingType === 'server') {
						allBidsForNode = [...serverBidsForNode];
					} else if (biddingType === 'parallel') {
						allBidsForNode = [...clientBidsForNode, ...serverBidsForNode];
					}
					bids.push(...allBidsForNode);
				});
				// Copy mediatype video params to params.video,
				// the ones that has not already been set.
				if (hasVideo) {
					bids.forEach((bid) => {
						// Don't add params that are meant for outstream video player only,
						// or internal setting mediaType and adserverTargetingOptions
						bid.params.video = _.defaults(bid.params.video, rawVideoSettings);
					});
				}
				const adUnit = {
					bids,
					siteId,
					placementId: placement.id,
					placementTypeId: placementType.id,
					data: placement.bidderParams({ sspId: 'genericPlacementData', pbjsConfigId: pbConfig.id }),
					...(videoSettings && { videoSettings }),
					...adserverUnitParams,
					adserverId: adserver.id,
					generalSettings: placement.bidderParams({ sspId: 'generalSettings', pbjsConfigId: pbConfig.id }),
					nativeSettings,
					formats,
					isInstream,
				};
				adUnits.push(adUnit);
				usedAdserverIds[adserver.id] = true;
			});
			const { allAdsIds, ...rest } = selectAdserverOrders(adUnits);
			if (allAdsIds.length) {
				const [hbaSiteId, numPlacs] = _.maxBy(_.entries(placsPerSite), ([, v]) => v) || [];
				configs[pbConfig.id] = {
					configId: pbConfig.id,
					abTestLocalStorage: pbConfig.abTestLocalStorage,
					...(pbConfigNode.isChildConfig && {
						parentConfigId: pbConfigNode.parentNode.id,
						percentage: pbConfig.percentage,
						enabled: pbConfig.enabled,
						country: pbConfig.country,
					}),
					hbaSiteId: numPlacs && (numPlacs || 0) > (placsPerSite[this.id] || 0) ? hbaSiteId : this.id,
					// Only one adserver supported
					allAdsIds,
					...rest,
					adUnits,
					name: pbConfig.name,
					placementTypesById,
					data: pbConfigNode.bidderParams({ sspId: 'genericPbConfigData' }),
					userSync: {
						userIds: enabledUserIdModules,
					},
				};
			}
		});
		return {
			globalAdserverSettings: _.mapValues(publisherNode.adserversById, (ads) => ({
				id: ads.id,
				type: ads.getTagTypeName(),
				adsPbReportConfigIdKey: ads.pbReportConfigIdKey || RLV_TARGET_PB_CFG_ID_KEY,
				adsPbReportConfigNameKey: ads.pbReportConfigNameKey || RLV_TARGET_PB_CFG_NAME_KEY,
				...ads.getPrebidAdServerSettings({
					hbmUsed: !!usedAdserverIds[ads.id],
				}),
				adsRtEnabled: ads.rtImporter?.enabled,
				...ads.floorProps,
				floorInfo: ads.getFloorInfo(),
			})),
			configs,
			aliases,
			s2sAliases,
			byBidderInfo,
			exchangeRates,
			siteData: this.bidderParams({ sspId: 'genericSiteData' }),
		};
	}
}
SiteNode.parentPath = 'websites';

module.exports = SiteNode;
