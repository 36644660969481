exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".small__src-components-Switch-styles__3vJF5 {\n    /* Negate negative margin set by FormControlLabel which is not correctly adjusted for <Switch size=\"small\" /> */\n    margin-left: 0 !important;\n}\n", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/worker/temp/relevant-yield/relevant-frontend/src/components/Switch/styles.css"],"names":[],"mappings":"AAAA;IACI,gHAA+G;IAC/G,0BAAyB;CAC7B","file":"styles.css","sourcesContent":[".small {\n    /* Negate negative margin set by FormControlLabel which is not correctly adjusted for <Switch size=\"small\" /> */\n    margin-left: 0 !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"small": "small__src-components-Switch-styles__3vJF5"
};