import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreIcon from '@mui/icons-material/MoreVert';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { Box,
	Tooltip,
	Menu,
	MenuList,
	MenuItem,
	ListItemText,
	ListItemIcon,
	IconButton,
	Button,
	styled,
	CircularProgress } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PushPinIcon from '@mui/icons-material/PushPin';
import TopBarHeader from './TopBarHeader';
import ReportSharingMenuButton from './ReportSharingMenuButton';
import SubscriptionsMenuButton from './SubscriptionsMenuButton';
import SystemData from '../../lib/systemData';
import { stores } from '../../stores';
import { ConfirmDialog } from '../ConfirmDialog';

const StyledLoadingButton = styled(LoadingButton)(({
	theme: { palette: { disabledDark } },
}) => ({
	'&:disabled': {
		color: disabledDark.main,
	},
}));

export default function TopBarReportOptions({
	title,
	isPublic,
	pinnedToSidebar,
	onSave,
	onExport,
	onSaveTemplate,
	onCopyToDashboard,
	onDelete,
	onSettingsClick,
	onShareClick,
	onIsPublicChange,
	onPinClick,
	shareUrl,
	status,
	onSubscriptionsChange,
	subscriptions,
	hasDynamicDateRange,
}) {
	const menuAnchorEl = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const isAdmin = stores.identity.isAdministrator();

	const hideSubscriptions = SystemData.genericData.systemSettings.hide.reportSubscriptionsForNormalUsers
		&& !stores.identity.isAdministrator();

	const handleClick = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const closeAfter = (fn) => async () => {
		await fn();
		setIsOpen(false);
	};

	// Don't show loading state unless half a second has passed
	// To avoid wonkiness in 90 % of cases, i.e when the action is very fast.
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (status === 'deleting' || status === 'saving') {
				setLoading(true);
			}
		}, 500);
		if (status === 'deleted' || status === 'saved') {
			setLoading(false);
			// Close menu on mobile devices after action is complete
			handleClose();
		}
		return () => clearTimeout(timeout);
	}, [status]);

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
			>
				<div>
					<TopBarHeader
						title={title}
						color="offWhite"
						icon={isPublic ? (
							<Tooltip title="Public">
								<GroupIcon />
							</Tooltip>
						) : null}
					/>
				</div>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					{/* Settings */}
					<Box sx={{ display: { xs: 'none', md: 'block' } }}>
						{ onSettingsClick && (
							<Button
								color="offWhite"
								variant="text"
								startIcon={<SettingsIcon />}
								onClick={onSettingsClick}
							>
								Settings
							</Button>
						)}
						{onSave && (
							<StyledLoadingButton
								disabled={status === 'deleting'}
								color="offWhite"
								startIcon={<SaveIcon />}
								variant="text"
								onClick={onSave}
								loadingPosition="start"
								loading={loading && status === 'saving'}
							>
								Save
							</StyledLoadingButton>
						)}
					</Box>
					{/* Export */}
					<Box sx={{ display: { xs: 'none', md: 'block' } }}>
						{onExport && (
							<Tooltip title="Export as .CSV">
								<Button
									color="offWhite"
									variant="text"
									startIcon={<FileDownloadIcon />}
									onClick={() => onExport()}
								>
									Export
								</Button>
							</Tooltip>
						)}
					</Box>
					{/* Save */}
					<Box sx={{ display: { xs: 'block', md: 'none' } }}>
						{onSave && (
							<Tooltip title="Save">
								<IconButton
									disabled={loading}
									color="offWhite"
									onClick={onSave}
								>
									{ loading && status === 'saving' ? (
										<CircularProgress size={24} color="disabledDark" />
									) : (
										<SaveIcon />
									)}
								</IconButton>
							</Tooltip>
						)}
						{ onSettingsClick && (
							<Tooltip title="Settings">
								<IconButton
									color="offWhite"
									onClick={onSettingsClick}
								>
									<SettingsIcon />
								</IconButton>
							</Tooltip>
						)}
					</Box>
					<IconButton
						color="offWhite"
						aria-label="more"
						ref={menuAnchorEl}
						onClick={handleClick}
					>
						<MoreIcon />
					</IconButton>
					<Menu
						anchorEl={menuAnchorEl.current}
						open={isOpen}
						onClose={handleClose}
					>
						<MenuList>
							{/* Save as Template */}
							{ isAdmin && (
								<MenuItem onClick={closeAfter(onSaveTemplate)} disabled={!onSaveTemplate}>
									<ListItemIcon>
										<SaveIcon />
									</ListItemIcon>
									<ListItemText>
										Save as Template
									</ListItemText>
								</MenuItem>
							)}
							{/* Copy to Dashboard */}
							<MenuItem onClick={closeAfter(onCopyToDashboard)}>
								<ListItemIcon>
									<DashboardCustomizeIcon />
								</ListItemIcon>
								<ListItemText>
									Copy to Dashboard
								</ListItemText>
							</MenuItem>
							{/* Share */}
							<ReportSharingMenuButton
								isPublic={isPublic}
								shareUrl={shareUrl}
								onClick={onShareClick}
								onIsPublicChange={onIsPublicChange}
								popoverAnchorEl={menuAnchorEl.current}
								onClose={handleClose}
							/>
							{ (onSubscriptionsChange && !hideSubscriptions) && (
								<SubscriptionsMenuButton
									subscriptions={subscriptions}
									onSubscriptionsChange={onSubscriptionsChange}
									popoverAnchorEl={menuAnchorEl.current}
									hasDynamicDateRange={hasDynamicDateRange}
									onClose={handleClose}
								/>
							)}
							{/* Pin/Unpin */}
							{ onPinClick && (
								<MenuItem onClick={() => {
									onPinClick();
									setIsOpen(false);
								}}
								>
									<ListItemIcon>
										<PushPinIcon />
									</ListItemIcon>
									<ListItemText>
										{!pinnedToSidebar ? 'Pin to sidebar' : 'Unpin from sidebar'}
									</ListItemText>
								</MenuItem>
							)}
							{/* Delete */}
							{ onDelete && (
								<ConfirmDialog
									text="Are you sure you want to delete this report?"
									onConfirm={onDelete}
								>
									<MenuItem>
										<ListItemIcon>
											{ (loading && status === 'deleting') ? (
												<CircularProgress size={24} />
											) : (
												<DeleteIcon />
											)}
										</ListItemIcon>
										<ListItemText>Delete</ListItemText>
									</MenuItem>
								</ConfirmDialog>
							)}
						</MenuList>
					</Menu>
				</Box>
			</Box>
		</Box>
	);
}

TopBarReportOptions.propTypes = {
	title: PropTypes.string,
	isPublic: PropTypes.bool,
	shareUrl: PropTypes.string,
	status: PropTypes.oneOf([
		'saving',
		'deleting',
		'saved',
		'deleted',
		'saveError',
		'deleteError',
	]),
	subscriptions: PropTypes.arrayOf(PropTypes.shape({
		recipients: PropTypes.arrayOf(PropTypes.shape({
			userId: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
		})),
		occurences: PropTypes.arrayOf(PropTypes.shape({
			type: PropTypes.oneOf(['daily', 'weekly', 'monthly', 'yearly']).isRequired,
		})).isRequired,
	})),
	pinnedToSidebar: PropTypes.bool,
	hasDynamicDateRange: PropTypes.func,
	onSave: PropTypes.func,
	onExport: PropTypes.func,
	onSaveTemplate: PropTypes.func,
	onCopyToDashboard: PropTypes.func,
	onDelete: PropTypes.func,
	onSettingsClick: PropTypes.func,
	onShareClick: PropTypes.func,
	onIsPublicChange: PropTypes.func,
	onSubscriptionsChange: PropTypes.func,
	onPinClick: PropTypes.func,
};

TopBarReportOptions.defaultProps = {
	title: '',
	isPublic: false,
	status: null,
	onSave: undefined,
	onExport: undefined,
	onSaveTemplate: undefined,
	onCopyToDashboard: undefined,
	onDelete: undefined,
	hasDynamicDateRange: () => false,
	subscriptions: [],
	onSettingsClick: undefined,
	onShareClick: undefined,
	onIsPublicChange: undefined,
	onSubscriptionsChange: undefined,
	onPinClick: undefined,
	pinnedToSidebar: false,
	shareUrl: '',
};
