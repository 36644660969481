import React from 'react';
import { demoMessage, isDemoUser } from '../DemoImport/utils';
import { Button } from '../Button/Button';

type ActionButtonProps = {
	label: string | React.ReactElement;
	demoAllow?: boolean;
	demoBlock?: boolean;
};

export function ActionButton({ label, demoAllow, demoBlock, ...rest }: ActionButtonProps & Parameters<typeof Button>[0]) {
	let { onClick } = rest;
	if (isDemoUser()) {
		const blockLabel = ['ok', 'save', 'submit'].indexOf(label?.toLowerCase?.() || '') >= 0;
		if ((blockLabel && !demoAllow) || demoBlock) {
			onClick = demoMessage;
		}
	}
	return <Button {...rest} {...(onClick && { onClick })}>{label}</Button>;
}
