exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".optionSubText__src-components-GoogleAnalyticsProfileSelect-styles__sdvco {\n    opacity: 0.5;\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/worker/temp/relevant-yield/relevant-frontend/src/components/GoogleAnalyticsProfileSelect/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAY;CAChB","file":"styles.css","sourcesContent":[".optionSubText {\n    opacity: 0.5;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"optionSubText": "optionSubText__src-components-GoogleAnalyticsProfileSelect-styles__sdvco"
};