import _ from 'lodash';
import uuid from 'uuid';

/**
 * If 'params' for an API-call contains a 'msgChannel' object, then we should do parallel calls to react
 * on "calls" from the backend and send back result. Purpose is to provide user feedback/input while
 * the main API call is ongoing. The 'msgChannel' object should have a structure like below:
 * { msgChannel: { [command1]: (params) => ...,  [command2]: (params) => ..., ... } }
 */
const withMsgChannel = (params, { MessageChannel }) => {
	const handlers = params?.msgChannel;
	if (!handlers) {
		return undefined;
	}
	const msgChannelId = uuid().toString();
	const msgChannel = { msgChannelId, commands: _.keys(handlers) };
	(async () => {
		try {
			let res = await MessageChannel.initChannel(msgChannel);
			let error;
			while (res) {
				const { command, params: p, oneWay } = res;
				if (command === 'onError') {
					error = params;
				}
				const handlerRes = await handlers[command]?.(p);
				try {
					if (oneWay) { // This was just a one-way message from backend, continue listening
						res = await MessageChannel.initChannel(msgChannel);
					} else { // Backend is waiting for reploy
						res = await MessageChannel.reply({ msgChannelId, params: handlerRes });
					}
				} catch (e) {
					console.error('Message channel broken: ', e);
					res = null;
					error = { msg: 'Message channel broken' };
				}
			}
			await handlers?.onChannelClosed({ ...(error && { error }) });
		} catch (e) {
			console.error('Message handler error: ', e);
		}
	})();
	return { msgChannel };
};

export {
	withMsgChannel,
};
